import {
  CardBody,
  Card,
} from '@polichat/flamboyant';
import React, { useState, useEffect } from 'react';
import {  Label, Button,  } from '@polichat/flamboyant';
import Animation from 'src/components/common/animation';
import store from 'src/store';
import { fetchOpportunitiesAndTicketsRequest } from 'src/store/modules/chat/actions';
import { ChevronRightLineLight2 } from '@poli-ink/react';
import {Opportunities} from './OpportunitiesDetails'
import { Tickets } from './TicketsDetails';
import { getFromLocalStorage, setToLocalStorage } from 'src/utils/localStorage';

export function OpportunitiesAndTickets(props) {
  const contact = props.contact;
  let opportunities = props.cards?.opportunities?.data ;

  if (opportunities && opportunities.length > 1 && props.onlyFirst) {
    opportunities = [opportunities[0]]
  }
  let tickets = props.cards?.tickets?.data ;

  if (tickets && tickets.length > 1 && props.onlyFirst) {
    tickets = [tickets[0]]
  }
  const [cards, setCards] = useState(null);
  const [error, setError] = useState(false);
  const [loading,setLoading] = useState(true);
  const handleTryAgain = () => {
    setCards(null);
    setLoading(true)
    setError(false)
    store.dispatch(fetchOpportunitiesAndTicketsRequest(contact.id));
  };

  useEffect(() => {
    setCards(props.cards);
    if(props.cards !== null){
      setLoading(false)
    }
    if (props.cards === undefined) {
      setError(true)
    }
    return ()=>{
      setLoading(true);
    }
  }, [props.cards]);

  useEffect(() => {    
    if (props.cards === null){
      setLoading(true);
      store.dispatch(fetchOpportunitiesAndTicketsRequest(contact.id));
    }
    return ()=>{
      setLoading(true);
    }
  }, [props.contact.id]);



  return (
    <>
      <div className="moreInfosOpportunities">
        <div className="title" onClick={props.toggleOpportunities}>
          <span>
            <strong>Oportunidades</strong>
          </span>
          <ChevronRightLineLight2 />
        </div>
        <Card className="infoItemsContainer">
          <CardBody className="infoItems">
            {loading ? (
              <Animation icon="loading" top={'50%'} />
            ) : error ? (
              <>
                <Label>Erro ao buscar</Label>
                <Button onClick={handleTryAgain}>Tentar novamente</Button>{' '}
              </>
            ) : (
              <>
                <Opportunities opportunities={opportunities} fnSeeMore={cards?.opportunities?.pagination?.total > 1 && props.onlyFirst ?props.toggleOpportunities:null}  />
              </>
            )}
          </CardBody>
        </Card>
      </div>
      <div className="moreInfosTickets">
        <div className="title" onClick={props.toggleTickets}>
          <span>
            <strong>Tickets</strong>
          </span>
          <ChevronRightLineLight2 />
        </div>

        <Card className="infoItemsContainer">
          <CardBody className="infoItems">
            {loading ? (
              <Animation icon="loading" top={'50%'} />
            ) : error ? (
              <>
                <Label>Erro ao buscar</Label>
                <Button onClick={handleTryAgain}>Tentar novamente</Button>{' '}
              </>
            ) : (
              <>
              <Tickets tickets={tickets} fnSeeMore={cards?.tickets?.pagination?.total > 1 && props.onlyFirst ?props.toggleTickets:null}  />
            </>
            )}
          </CardBody>
        </Card>
      </div>
    </>
  );
}
