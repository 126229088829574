import React, { useEffect, useState } from 'react';
import { SearchCustomer } from 'src/components/common/structure/header/searchCustomer/index.tsx';
import packageJson from '../../../../../package.json';
import { logout as logoutAction } from '../../../../store/modules/auth/actions';
import { toggleChangelog } from '../../../../store/modules/changelog/actions';
import poliConfig from '../../../../utils/poliConfig';

import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Icon,
} from '@polichat/flamboyant';
import { useLocation } from 'react-router-dom';
import DarkMode from '../header/actions/DarkMode';
import Organizations from '../header/actions/Organizations';

import { ChevronRightLine, Credit, Out, Search } from '@poli-ink/react';

import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../store/modules/chat/actions';

import SidebarItem from './SidebarItem';
import SidebarMainIcon from './SidebarLogo';

import { useFlags } from 'flagsmith/react';
import { Routers } from '../../../../routes';
import Avatar from '../../profile/Avatar';

import { ContactCredits } from '../header/actions/ContactCredits';
import { ToggleNotifications } from '../header/actions/ToggleNotifications';
import { UserStatus } from '../header/actions/UserStatus';

function Sidebar({ config, userConfig, current_customer_id, isMobile }) {
  const routes = Routers();
  const flag = useFlags(['can_view_crm']);
  const [statusUser, setStatusUser] = useState();

  const isAuvo =
    useSelector((state) => state?.whitelabel?.config?.app_title) === 'Auvo';

  useEffect(() => {
    if (isMobile) {
      handleMenu();
    }
  }, [onload]);

  const enumStatus = [
    {
      id: 1,
      name: 'Disponível',
      description: 'Na fila para receber novos contatos',
      color: 'var(--color-success)',
      show: true,
    },
    {
      id: 0,
      name: 'Desconectado',
      description: '',
      color: 'var(--danger)',
      show: false,
    },
    {
      id: -1,
      name: 'Indisponível',
      description: 'Você não receberá novos contatos',
      color: 'var(--color-warning)',
      show: true,
    },
  ];

  /**
   * Whitelabel
   */
  const wlState = useSelector((state) => state?.whitelabel);
  const [isWl, setIsWl] = useState();
  const [isWlNotHome, setIsWlNotHome] = useState();
  const [isHelp, setIsHelp] = useState();
  const [isUser, setIsUser] = useState();
  const [loginUrlReseller, setLoginUrlReseller] = useState();

  const flagVersionChangelog = useFlags([
    'version_changelog',
  ]).version_changelog;

  useEffect(() => {
    const _config = wlState?.config;
    const _isWl = _config?.is_white_label;

    const _home = _config?.buttons?.find((e) => e.id === 'home');
    const _help = _config?.buttons?.find((e) => e.id === 'help');
    const _user = _config?.buttons?.find((e) => e.id === 'user');

    setIsWlNotHome(!_home?.id && _isWl);
    setIsHelp(_help?.id);
    setIsUser(_user?.id);
    setLoginUrlReseller(_config?.login_url_reseller);

    setIsWl(_isWl);
  }, [wlState]);
  /* end */

  let avatarOperator;
  let nameOperator;
  let idOperator;
  let currentCustomer;
  const multiCustomers = userConfig?.customers.length > 1 ? true : false;
  const superRole = userConfig?.superRole;
  const dispatch = useDispatch();

  const enableAds =
    poliConfig?.ads_enable ||
    poliConfig?.ads_enable_custumer?.includes(Number(current_customer_id));

  // Validações para utilizar o nome, id e imagem do operador
  if (!userConfig || userConfig === undefined) {
    avatarOperator = '';
    nameOperator = '';
    idOperator = '';
  } else {
    nameOperator = userConfig.name;
    avatarOperator = userConfig.picture;

    idOperator = userConfig.id;
    currentCustomer = current_customer_id;
  }

  let location = useLocation();
  function isActive(path, subitens) {
    if (path === location.pathname) return true;
    if (!subitens) return false;
    return RegExp(path + '/.+', 'gi').test(location.pathname);
  }

  function isPwa() {
    return localStorage.getItem('pwa');
  }

  const [operatorMenu, setOperatorMenu] = useState(false);
  const [supportMenu, setSupportMenu] = useState(false);

  const toggle = () => setOperatorMenu((prevState) => !prevState);

  //Verifica se tem a classe MenuRetratil na cache do Browser
  const toggleMenu = () => {
    if (localStorage.getItem('@polichat/menuRetratil') === 'true') {
      document.getElementById('root').classList.add('menuRetratil');
      return true;
    }
    return false;
  };

  const getEnumStatusById = (id) => {
    return enumStatus.find((e) => e.id === id);
  };

  const currentOrganization = userConfig?.customers.find(
    (customer) => customer.id === current_customer_id
  );

  const [menuRetratil, handleMenuRetratil] = useState(toggleMenu());

  // Função que aciona o Menu Retrátil
  function handleMenu() {
    const rootElement = document.getElementById('root');
    if (rootElement.classList.contains('menuRetratil')) {
      // Aumentou o menu
      handleMenuRetratil(false);
      rootElement.classList.remove('menuRetratil');
      localStorage.removeItem('@polichat/menuRetratil');
    } else {
      // Diminuiu o menu
      handleMenuRetratil(true);
      rootElement.classList.add('menuRetratil');
      localStorage.setItem('@polichat/menuRetratil', true);
    }
  }

  async function logout() {
    dispatch(logoutAction());
    window.location.href = '/login';
  }

  function myAccount() {
    if (isWl && loginUrlReseller?.toString()?.length > 2) {
      window.location.href = loginUrlReseller;
    } else {
      window.location.href = `${poliConfig.poliAppLaravelUrl}/users/${currentCustomer}/${idOperator}/edit`;
    }
  }

  function handleVersionChangelog() {
    if (flagVersionChangelog.enabled) {
      dispatch(toggleChangelog());
    }
  }

  function cutName(name) {
    const arrName = name?.split(' ');
    const removeShortName = arrName?.filter((value) => {
      if (value.length > 2) {
        return value;
      }
    });
    if (removeShortName?.length != 0 && removeShortName?.length >= 2) {
      return `${removeShortName[0]} ${removeShortName[1]}`;
    }
    return name;
  }

  return (
    <>
      <SidebarMainIcon />
      <ul className="sidebarMenu">
        <div className="showMenuRetratil menuRetratilTop">
          {menuRetratil ? (
            <div onClick={handleMenu}>
              <Credit />
            </div>
          ) : (
            <>{!isWl && <ContactCredits />}</>
          )}
        </div>
        {superRole === 'adm' && (
          <div className="showMenuRetratil menuRetratilTop">
            {menuRetratil ? (
              <div onClick={handleMenu}>
                <Search />
              </div>
            ) : (
              <SearchCustomer />
            )}
          </div>
        )}
        {routes.map((route, index) => {
          let active = isActive(route.path, route.subitens);
          //if (route.path === location.pathname) active = true;

          let show = true;
          let path = route.path;

          // is PWA
          if (isPwa()) {
            show = route.showPwa;
            if (route.path === '/') {
              path = '/chat';
            }
          }

          if (
            route.isNew &&
            !flag.can_view_crm?.value?.includes(current_customer_id)
          ) {
            route.showIsNew = true;
            path =
              'https://api.whatsapp.com/send/?phone=556299800123&text=Quero%20conhecer%20o%20CRM&type=phone_number&app_absent=0';
          }

          // redirect home wl
          if (isWlNotHome) {
            if (route.path === '/') {
              path = '/chat';
            }
          }

          if (!enableAds && path === '/ads') {
            return <></>;
          }

          if (route.sidebarName && show) {
            return (route.needAdmin && superRole === 'adm') ||
              !route.needAdmin ? (
              <>
                <li key={index}>
                  <SidebarItem
                    key={index}
                    name={route.sidebarName}
                    url={path}
                    icon={route.icon}
                    active={active}
                    title={route.title}
                    openNewPage={
                      route?.openNewPage || route?.openNewPage === false
                        ? route.openNewPage
                        : null
                    }
                  />
                </li>
                {route.showIsNew && (
                  <div className="newFunctionality">
                    <span>Novo</span>
                  </div>
                )}
              </>
            ) : null;
          }
        })}
      </ul>

      <div className="sidebarUserConfig">
        <div>
          {isHelp && (
            <>
              <div className="supportMenu sidebarMenuItem">
                <Button color={'link'} className="notification">
                  <Icon icon="poli-icon pi-bell-fill" />
                  <span>Notificação</span>
                </Button>
              </div>
              <div className="supportMenu sidebarMenuItem">
                <Dropdown
                  direction="right"
                  isOpen={supportMenu}
                  toggle={() => setSupportMenu((prevState) => !prevState)}
                >
                  <DropdownToggle color={'link'} className="supportMenuButton">
                    <Icon icon="poli-icon pi-question-fill" />
                    <span>Suporte</span>
                  </DropdownToggle>
                  <DropdownMenu className="menuDropdownShow">
                    <DropdownItem
                      className="itemDropdownMenu"
                      onClick={() =>
                        window.open('https://forms.gle/f8FGaGFENdgUbo9r9')
                      }
                    >
                      <Icon
                        icon={'poli-icon pi-alerts-line'}
                        color={'var(--green-polichat)'}
                        size={25}
                        position={'relative'}
                        left={'2px'}
                      />
                      <p className="alternative">Sugestões</p>
                      <Icon
                        icon={'poli-icon pi-simple-arrow-right-line'}
                        position={'relative'}
                        size={15}
                      />
                    </DropdownItem>

                    <DropdownItem
                      className="itemDropdownMenu"
                      onClick={() =>
                        window.open('https://poli.digital/central-de-ajuda/')
                      }
                    >
                      <Icon
                        icon={'poli-icon pi-info-line'}
                        color={'var(--green-polichat)'}
                        size={24}
                      />
                      <p className="alternative">Central de Ajuda</p>
                      <Icon
                        icon={'poli-icon poli-icon pi-simple-arrow-right-line'}
                        position={'relative'}
                        size={15}
                      />
                    </DropdownItem>

                    <DropdownItem
                      className="itemDropdownMenu"
                      onClick={() =>
                        window.open('mailto:contato@polichat.com.br')
                      }
                    >
                      <Icon
                        icon={'poli-icon pi-mail-line'}
                        color={'var(--green-polichat)'}
                        size={24}
                      />
                      <p className="alternative">Suporte via E-mail</p>
                      <Icon
                        icon={'poli-icon poli-icon pi-simple-arrow-right-line'}
                        size={15}
                      />
                    </DropdownItem>

                    <DropdownItem
                      className="itemDropdownMenu"
                      onClick={() => {
                        window.open(
                          'https://web.whatsapp.com/send?phone=5562999800123'
                        );
                      }}
                    >
                      <Icon
                        icon={'poli-icon pi-whatsapp-line'}
                        color={'var(--green-polichat)'}
                        size={24}
                      />
                      <p className="alternative">Suporte via Whatsapp</p>
                      <Icon
                        icon={'poli-icon poli-icon pi-simple-arrow-right-line'}
                        size={15}
                      />
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </>
          )}

          {isUser && !isAuvo && (
            <div className="operatorMenu" id={idOperator}>
              <Dropdown direction="right" isOpen={operatorMenu} toggle={toggle}>
                <DropdownToggle color={'insivible'}>
                  <Avatar
                    className="operator"
                    title={nameOperator}
                    url={avatarOperator}
                    extraClass="avatarSidebar"
                    size={30}
                  />
                  <p className="operatorName">{cutName(nameOperator)}</p>
                </DropdownToggle>
                <div
                  className="statusUserIconNav miniStatusUser"
                  style={{
                    backgroundColor: getEnumStatusById(statusUser)?.color,
                    border: '3px solid var(--primary-background-color)',
                  }}
                />
                <DropdownMenu className="menuDropdownShow container__operator--menu">
                  <>
                    <div>
                      <div className="userTitleName">
                        <h3>{cutName(config?.user?.name ?? '')}</h3>
                        <div onClick={myAccount} style={{ cursor: 'pointer' }}>
                          <Icon
                            icon={'poli-icon pi-pencil-fill'}
                            size={30}
                            color={'var(--activated-icons-color)'}
                          />
                        </div>
                      </div>
                      <span className="userCustomer">
                        {currentOrganization?.name}
                      </span>
                    </div>
                    <span className="userTitle">Tema</span>
                    <div className="themeDiv">
                      Claro
                      <DarkMode />
                      Escuro
                    </div>
                    <hr />
                    <span className="userTitle">Status</span>
                    <UserStatus miniStatusUser={setStatusUser} />
                    <hr />
                    <div>
                      <span className="userTitle">Personalização</span>
                      <ToggleNotifications />
                    </div>
                    <hr />
                    <span className="userTitle">Perfil da empresa</span>
                    <Organizations />
                    <hr style={{ margin: '5px 0px 16px 0px' }} />
                  </>
                  <div className="mobileTopActions">
                    {multiCustomers && (
                      <>
                        <Organizations />
                        <br />
                      </>
                    )}
                    <DarkMode />
                  </div>
                  {!isWl && (
                    <DropdownItem
                      className="itemDropdownMenu operatorExitPolichat"
                      onClick={logout}
                    >
                      <Out width={16} height={16} />
                      <p className="alternative">Sair</p>
                      <ChevronRightLine width={16} height={16} />
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>
            </div>
          )}

          <div
            id="retratilButton"
            className="showMenuRetratil menuRetratilClass"
            onClick={handleMenu}
          >
            {menuRetratil ? (
              <Icon
                position={'relative'}
                top={'2px'}
                left={'-3px'}
                icon={'poli-icon poli-icon pi-next-fill'}
              />
            ) : (
              <Icon
                position={'relative'}
                top={'-2px'}
                icon={'poli-icon poli-icon pi-previous-fill'}
              />
            )}
            <span>Recolher Menu</span>
          </div>

          <div className="showVersion" onClick={() => handleVersionChangelog()}>
            <span className="numberVersion">{packageJson?.version}</span>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({
  config: state.chat.config,
  userConfig: state?.general?.user,
  props: ownProps,
  current_customer_id: state.general?.current_customer_id,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
