
export const actionNames = {
  OPEN_SOCKET_REQUEST: '@chat/OPEN_SOCKET_REQUEST',
  OPEN_SOCKET_SUCCESS: '@chat/OPEN_SOCKET_SUCCESS',
  OPEN_SOCKET_FAILURE: '@chat/OPEN_SOCKET_FAILURE',

  FETCH_CHAT_CONFIG_REQUEST: '@chat/FETCH_CHAT_CONFIG_REQUEST',
  FETCH_CHAT_CONFIG_SUCCESS: '@chat/FETCH_CHAT_CONFIG_SUCCESS',
  FETCH_CHAT_CONFIG_FAILURE: '@chat/FETCH_CHAT_CONFIG_FAILURE',

  // Ações relacionadas aos contatos
  FETCH_CONTACTS_REQUEST: '@chat/FETCH_CONTACTS_REQUEST',
  FETCH_CONTACTS_SUCCESS: '@chat/FETCH_CONTACTS_SUCCESS',
  FETCH_CONTACTS_FAILURE: '@chat/FETCH_CONTACTS_FAILURE',
  FETCH_MORE_CONTACTS_REQUEST: '@chat/FETCH_MORE_CONTACTS_REQUEST',
  FETCH_MORE_CONTACTS_SUCCESS: '@chat/FETCH_MORE_CONTACTS_SUCCESS',
  FETCH_MORE_CONTACTS_FAILURE: '@chat/FETCH_MORE_CONTACTS_FAILURE',
  START_FETCHING_CONTACTS_LOADING: '@chat/START_FETCHING_CONTACTS_LOADING',

  // Ações para criar um novo contato
  CREATE_CONTACT_REQUEST: '@chat/CREATE_CONTACT_REQUEST',
  CREATE_CONTACT_SUCCESS: '@chat/CREATE_CONTACT_SUCCESS',
  CREATE_CONTACT_FAILURE: '@chat/CREATE_CONTACT_FAILURE',

  // Ações para atualizar um contato já existente
  UPDATE_CONTACT_REQUEST: '@chat/UPDATE_CONTACT_REQUEST',
  UPDATE_CONTACT_SUCCESS: '@chat/UPDATE_CONTACT_SUCCESS',
  UPDATE_CONTACT_FAILURE: '@chat/UPDATE_CONTACT_FAILURE',

  // Ação para Deletar um contato external
  DELETE_CONTACT_EXTERNAL_REQUEST: '@chat/DELETE_CONTACT_EXTERNAL_REQUEST',
  DELETE_CONTACT_EXTERNAL_SUCCESS: '@chat/DELETE_CONTACT_EXTERNAL_SUCCESS',
  DELETE_CONTACT_EXTERNAL_FAILURE: '@chat/DELETE_CONTACT_EXTERNAL_FAILURE',

  FETCH_CONTACT_CONFIG_REQUEST: '@chat/FETCH_CONTACT_CONFIG_REQUEST',
  FETCH_CONTACT_CONFIG_SUCCESS: '@chat/FETCH_CONTACT_CONFIG_SUCCESS',
  FETCH_CONTACT_CONFIG_FAILURE: '@chat/FETCH_CONTACT_CONFIG_FAILURE',

  // Ações para sucesso ou falha ao pesquisar contatos
  CONTACT_SEARCH_SUCCESS: '@chat/CONTACT_SEARCH_SUCCESS',
  CONTACT_SEARCH_FAILURE: '@chat/CONTACT_SEARCH_FAILURE',

  // Ações para busca de contatos para serem vinculados
  MERGE_CONTACT_SEARCH: '@chat/MERGE_CONTACT_SEARCH',
  MERGE_CONTACT_SEARCH_SUCCESS: '@chat/MERGE_CONTACT_SEARCH_SUCCESS',
  MERGE_CONTACT_SEARCH_FAILURE: '@chat/MERGE_CONTACT_SEARCH_FAILURE',
  MERGE_CONTACTS: '@chat/MERGE_CONTACTS',
  MERGE_CONTACTS_SUCCESS: '@chat/MERGE_CONTACTS_SUCCESS',
  MERGE_CONTACTS_FAILURE: '@chat/MERGE_CONTACTS_FAILURE',
  UNMERGE_CONTACTS: '@chat/UNMERGE_CONTACTS',
  UNMERGE_CONTACTS_SUCCESS: '@chat/UNMERGE_CONTACTS_SUCCESS',
  UNMERGE_CONTACTS_FAILURE: '@chat/UNMERGE_CONTACTS_FAILURE',

  FETCH_COUNT_NO_READS: '@chat/FETCH_COUNT_NO_READS',
  FETCH_COUNT_NO_READS_SUCCESS: '@chat/FETCH_COUNT_NO_READS_SUCCESS',
  FETCH_COUNT_NO_READS_FAILURE: '@chat/FETCH_COUNT_NO_READS_FAILURE',

  STORE_USER_SETTINGS: '@chat/STORE_USER_SETTINGS',

  SHOW_DETAILS_COMPONENT: '@chat/SHOW_DETAILS_COMPONENT',

  OPEN_TAG_IN_DETAILS: '@chat/OPEN_TAG_IN_DETAILS',

  FETCH_CHAT_REQUEST: '@chat/FETCH_CHAT_REQUEST',
  FETCH_CHAT_SUCCESS: '@chat/FETCH_CHAT_SUCCESS',
  FETCH_CHAT_FAILURE: '@chat/FETCH_CHAT_FAILURE',

  FETCH_MESSAGES_REQUEST: '@chat/FETCH_MESSAGES_REQUEST',
  FETCH_MESSAGES_REQUEST_ONE_CHAT: '@chat/FETCH_MESSAGES_REQUEST_ONE_CHAT',
  ONE_CHAT_LOADED: '@chat/ONE_CHAT_LOADED',
  FETCH_MESSAGES_SUCCESS: '@chat/FETCH_MESSAGES_SUCCESS',
  FETCH_MESSAGES_FAILURE: '@chat/FETCH_MESSAGES_FAILURE',

  FETCH_MORE_MESSAGES_REQUEST: '@chat/FETCH_MORE_MESSAGES_REQUEST',
  FETCH_MORE_MESSAGES_SUCCESS: '@chat/FETCH_MORE_MESSAGES_SUCCESS',
  FETCH_MORE_MESSAGES_FAILURE: '@chat/FETCH_MORE_MESSAGES_FAILURE',

  FETCH_MORE_CHATS_REQUEST: '@chat/FETCH_MORE_CHATS_REQUEST',
  FETCH_MORE_CHATS_SUCCESS: '@chat/FETCH_MORE_CHATS_SUCCESS',
  UPDATE_CURRENT_PAGE_CUSTOM_FILTER: '@chat/UPDATE_CURRENT_PAGE_CUSTOM_FILTER',
  FETCH_MORE_CHATS_FAILURE: '@chat/FETCH_MORE_CHATS_FAILURE',

  FETCH_PENDENCES_REQUEST: '@chat/FETCH_PENDENCES_REQUEST',
  FETCH_PENDENCES_CHATS_SUCCESS: '@chat/FETCH_PENDENCES_CHATS_SUCCESS',
  FETCH_PENDENCES_CHATS_FAILURE: '@chat/FETCH_PENDENCES_CHATS_FAILURE',

  START_FETCHING_CHATS_SEARCH_LOADING:
    '@chat/START_FETCHING_CHATS_SEARCH_LOADING',

  FETCH_CONTACT_EXTERNAL: '@chat/FETCH_CONTACT_EXTERNAL',
  FETCH_CONTACT_EXTERNAL_SUCCESS: '@chat/FETCH_CONTACT_EXTERNAL_SUCCESS',

  FETCH_USERS_REQUEST: '@chat/FETCH_USERS_REQUEST',
  FETCH_USERS_FAILURE: '@chat/FETCH_USERS_FAILURE',
  FETCH_USERS_SUCCESS: '@chat/FETCH_USERS_SUCCESS',
  UPDATE_CURRENT_CUSTOMER_ID: '@chat/UPDATE_CURRENT_CUSTOMER_ID',

  OPEN_CHAT: '@chat/OPEN_CHAT',
  CLOSE_CHAT: '@chat/CLOSE_CHAT',
  MARK_READ: '@chat/MARK_READ',

  OPEN_FINISH_CHAT_MODAL: '@chat/OPEN_FINISH_CHAT_MODAL',
  CLOSE_FINISH_CHAT_MODAL: '@chat/CLOSE_FINISH_CHAT_MODAL',

  OPEN_TRANSFER_CHAT_MODAL: '@chat/OPEN_TRANSFER_CHAT_MODAL',
  OPEN_TRANSFER_CHAT_MODAL_SUCCESS: '@chat/OPEN_TRANSFER_CHAT_MODAL_SUCCESS',
  CLOSE_TRANSFER_CHAT_MODAL: '@chat/CLOSE_TRANSFER_CHAT_MODAL',

  // Ações relacionadas a etiqueta
  OPEN_TAG_CHAT_MODAL: '@chat/OPEN_TAG_CHAT_MODAL',
  GET_TAGS: '@chat/GET_TAGS',
  CLOSE_TAG_CHAT_MODAL: '@chat/CLOSE_TAG_CHAT_MODAL',
  INSERT_TAG_TO_CONTACT: '@chat/INSERT_TAG_TO_CONTACT',
  REMOVE_TAG_FROM_CONTACT: '@chat/REMOVE_TAG_FROM_CONTACT',
  INSERT_TAG_TO_CONTACT_SUBMIT: '@chat/INSERT_TAG_TO_CONTACT_SUBMIT',
  INSERT_TAG_TO_CONTACT_REQUEST: '@chat/INSERT_TAG_TO_CONTACT_REQUEST',
  INSERT_TAG_TO_CONTACT_SUCCESS: '@chat/INSERT_TAG_TO_CONTACT_SUCCESS',
  INSERT_TAG_TO_CONTACT_FAILURE: '@chat/INSERT_TAG_TO_CONTACT_FAILURE',
  REMOVE_TAG_FROM_CONTACT_REQUEST: '@chat/REMOVE_TAG_FROM_CONTACT_REQUEST',
  REMOVE_TAG_FROM_CONTACT_SUCCESS: '@chat/REMOVE_TAG_FROM_CONTACT_SUCCESS',

  INCOMING_TAG_CREATED: '@chat/INCOMING_TAG_CREATED',
  INCOMING_TAG_DELETED: '@chat/INCOMING_TAG_DELETED',

  TRANSFER_CHAT_REQUEST: '@chat/TRANSFER_CHAT_REQUEST',
  TRANSFER_CHAT_SUCCESS: '@chat/TRANSFER_CHAT_SUCCESS',
  TRANSFER_CHAT_FAILURE: '@chat/TRANSFER_CHAT_FAILURE',

  FINISH_CHAT_REQUEST: '@chat/FINISH_CHAT_REQUEST',
  FINISH_CHAT_SUCCESS: '@chat/FINISH_CHAT_SUCCESS',
  FINISH_CHAT_FAILURE: '@chat/FINISH_CHAT_FAILURE',

  PUT_MESSAGE_IN_STORE: '@chat/PUT_MESSAGE_IN_STORE',

  FETCH_OPERATORS_LIST_REQUEST: '@chat/FETCH_OPERATORS_LIST_REQUEST',
  FETCH_OPERATORS_LIST_SUCCESS: '@chat/FETCH_OPERATORS_LIST_SUCCESS',
  FETCH_OPERATORS_LIST_FAILURE: '@chat/FETCH_OPERATORS_LIST_FAILURE',

  // Ações responsáveis pelo upload de arquivos
  ADD_UPLOAD_TO_LIST: '@chat/ADD_UPLOAD_TO_LIST',
  UPDATE_STATE_UPLOAD_FILES_MESSAGE: '@chat/UPDATE_STATE_UPLOAD_FILES_MESSAGE',
  CLEAR_LIST_UPLOAD: '@chat/CLEAR_LIST_UPLOAD',
  CREATE_UPLOAD_LIST: '@chat/CREATE_UPLOAD_LIST',
  SELECTED_CHAT_ID: '@chat/SELECTED_CHAT_ID',
  REMOVE_ITEM_UPLOAD_LIST: '@chat/REMOVE_ITEM_UPLOAD_LIST',
  UPDATE_CHAT_DROPZONE: '@chat/UPDATE_CHAT_DROPZONE',
  CLEAR_UPLOAD_AFTER_SEND: '@chat/CLEAR_UPLOAD_AFTER_SEND',

  // Ações reponsáveis pelo envio de mensagens - texto e mídia
  ADD_MESSAGE: '@chat/ADD_MESSAGE',
  SEND_MESSAGE: '@chat/SEND_MESSAGE',
  SEND_MESSAGE_FAILURE: '@chat/SEND_MESSAGE_FAILURE',
  SEND_MESSAGE_SUCCESS: '@chat/SEND_MESSAGE_SUCCESS',

  RECEIVE_ACK: '@chat/RECEIVE_ACK',
  RECEIVE_MESSAGE: '@chat/RECEIVE_MESSAGE',
  RESET_COUNTER_NEW_MESSAGE: '@chat/RESET_COUNTER_NEW_MESSAGE',

  STORE_RECEIVED_MESSAGE: '@chat/STORE_RECEIVED_MESSAGE',
  STORE_RECEIVED_CHAT_WITH_MESSAGE: '@chat/STORE_RECEIVED_CHAT_WITH_MESSAGE',

  SELECT_CHANNEL: '@chat/SELECT_CHANNEL',
  SELECT_CONTACT_EXTERNAL: '@chat/SELECT_CONTACT_EXTERNAL',

  // STORE_MESSAGE_WITHOUT_CHAT: '@chat/STORE_MESSAGE_WITHOUT_CHAT',
  // MERGE_CHAT_WITH_MESSAGE: '@chat/MERGE_CHAT_WITH_MESSAGE',
  CREATE_MESSAGE_TEXTAREA: '@chat/CREATE_MESSAGE_TEXTAREA',
  UPDATE_MESSAGE_TEXTAREA: '@chat/UPDATE_MESSAGE_TEXTAREA',

  UPDATE_MESSAGE_URL: '@chat/UPDATE_MESSAGE_URL',

  // Relacionado ao contato
  OPEN_CHAT_TAB: '@chat/OPEN_CHAT_TAB',
  OPEN_CONTACT_TAB: '@chat/OPEN_CONTACT_TAB',
  OPEN_INPROGRESS_CHATS_TAB: '@chat/OPEN_INPROGRESS_CHATS_TAB',
  OPEN_NOATTENDANT_TAB: '@chat/OPEN_NOATTENDANT_TAB',
  OPEN_UNREADS_TAB: '@chat/OPEN_UNREADS_TAB',
  SETUP_INITIAL_CONTACT_CONTENT: '@chat/SETUP_INITIAL_CONTACT_CONTENT',

  // Relacionados aos estados que manipulam os componentes ativos
  SET_OPEN_ADD_CONTACT: '@chat/SET_OPEN_ADD_CONTACT',
  SET_OPEN_IMPORT_CONTACT: '@chat/SET_OPEN_IMPORT_CONTACT',
  SET_OPEN_EDIT_CONTACT: '@chat/SET_OPEN_EDIT_CONTACT',
  FETCH_UNIQUE_CHAT_REQUEST: '@chat/FETCH_UNIQUE_CHAT_REQUEST',
  FETCH_UNIQUE_CHAT_SUCCESS: '@chat/FETCH_UNIQUE_CHAT_SUCCESS',
  FETCH_UNIQUE_CHAT_FAILURE: '@chat/FETCH_UNIQUE_CHAT_FAILURE',
  FETCH_UNIQUE_CHAT: '@chat/FETCH_UNIQUE_CHAT',

  SET_ADD_CARD_CONTACT: '@chat/SET_ADD_CARD_CONTACT',
  UNSET_ADD_CARD_CONTACT: '@chat/UNSET_ADD_CARD_CONTACT',

  /**
   * Incoming Channel Events
   */
  STORE_CHANNEL: '@chat/STORE_CHANNEL',
  UPDATE_CHANNEL: '@chat/UPDATE_CHANNEL',
  DELETE_CHANNEL: '@chat/DELETE_CHANNEL',
  RESTORE_CHANNEL: '@chat/RESTORE_CHANNEL',

  TOGGLE_FILTER_MENU: '@chat/TOGGLE_FILTER_MENU',
  CLEAR_FILTER: '@chat/CLEAR_FILTER',
  CLEAR_CUSTOM_FILTER_PAGE: '@chat/CLEAR_CUSTOM_FILTER_PAGE',

  SUBMIT_FILTER: '@chat/SUBMIT_FILTER',
  UPDATE_FILTERS: '@chat/UPDATE_FILTERS',
  REMOVE_FILTER: '@chat/REMOVE_FILTER',
  UPDATE_FILTER_HISTORY: '@chat/UPDATE_FILTER_HISTORY',

  UPDATE_CHAT_SEARCH: '@chat/UPDATE_CHAT_SEARCH',
  UPDATE_CONTACT_SEARCH: '@chat/UPDATE_CONTACT_SEARCH',
  SEARCH: '@chat/SEARCH',
  PUT_RESULTS_IN_SEARCH_CHATS: '@chat/PUT_RESULTS_IN_SEARCH_CHATS',
  CLEAR_RESULTS_IN_SEARCH_CHATS: '@chat/CLEAR_RESULTS_IN_SEARCH_CHATS',

  STORE_CHAT_CONTACT: '@chat/STORE_CHAT_CONTACT',
  REMOVE_CHAT_CONTACT: '@chat/REMOVE_CHAT_CONTACT',

  STORE_CHAT_USER: '@chat/STORE_CHAT_USER',
  REMOVE_CHAT_USER: '@chat/REMOVE_CHAT_USER',

  FETCH_QUICK_MESSAGES: '@chat/FETCH_QUICK_MESSAGES',
  FETCH_QUICK_MESSAGES_SUCCESS: '@chat/FETCH_QUICK_MESSAGES_SUCCESS',
  FETCH_QUICK_MESSAGES_FAILURE: '@chat/FETCH_QUICK_MESSAGES_FAILURE',
  TOGGLE_QUICK_MESSAGES_SELECTOR: '@chat/TOGGLE_QUICK_MESSAGES_SELECTOR',
  NAV_QUICK_MESSAGES_SELECTOR: '@chat/NAV_QUICK_MESSAGES_SELECTOR',
  SEARCH_QUICK_MESSAGES: '@chat/SEARCH_QUICK_MESSAGES',
  CREATE_QUICK_MESSAGE: '@chat/CREATE_QUICK_MESSAGE',
  CREATE_QUICK_MESSAGE_SUCCESS: '@chat/CREATE_QUICK_MESSAGE_SUCCESS',
  CREATE_QUICK_MESSAGE_FAILURE: '@chat/CREATE_QUICK_MESSAGE_FAILURE',
  UPDATE_QUICK_MESSAGE: '@chat/UPDATE_QUICK_MESSAGE',
  UPDATE_QUICK_MESSAGE_SUCCESS: '@chat/UPDATE_QUICK_MESSAGE_SUCCESS',
  UPDATE_QUICK_MESSAGE_FAILURE: '@chat/UPDATE_QUICK_MESSAGE_FAILURE',
  CREATED_QUICK_MESSAGE: '@chat/CREATED_QUICK_MESSAGE',
  UPDATED_QUICK_MESSAGE: '@chat/UPDATED_QUICK_MESSAGE',
  DELETED_QUICK_MESSAGE: '@chat/DELETED_QUICK_MESSAGE',
  ALERT_DELETE_QUICK_MESSAGE: '@chat/ALERT_DELETE_QUICK_MESSAGE',
  DELETE_QUICK_MESSAGE: '@chat/DELETE_QUICK_MESSAGE',
  EDIT_QUICK_MESSAGE: '@chat/EDIT_QUICK_MESSAGE',
  CANCEL_EDIT_QUICK_MESSAGE: '@chat/CANCEL_EDIT_QUICK_MESSAGE',
  CANCEL_DELETE_QUICK_MESSAGE: '@chat/CANCEL_DELETE_QUICK_MESSAGE',

  PREPARE_RECORDER: '@chat/PREPARE_RECORDER',

  UPDATE_SETTINGS: '@chat/UPDATE_SETTINGS',

  UPDATE_CUSTOMER_ONBOARDING: '@chat/UPDATE_CUSTOMER_ONBOARDING',

  SEND_FILES_MESSAGE: '@chat/SEND_FILES_MESSAGE',
  UPLOAD_FILE_MESSAGE: '@chat/UPLOAD_FILE_MESSAGE',

  REPLYING_MESSAGE: '@chat/REPLYING_MESSAGE',
  CLEAR_REPLYING_MESSAGE: '@chat/CLEAR_REPLYING_MESSAGE',

  //------->>>>>
  // START_VISITORS_SERVICE: '@chat/START_VISITORS_SERVICE',
  // START_VISITORS_SERVICE_FAILURE: '@chat/START_VISITORS_SERVICE_FAILURE',
  // START_VISITORS_SERVICE_SUCCESS: '@chat/START_VISITORS_SERVICE_SUCCESS',

  // STORE_FROM_VISITOR: '@chat/STORE_FROM_VISITOR',
  // STORE_FROM_VISITOR_SUCCESS: '@chat/STORE_FROM_VISITOR_SUCCESS',
  // STORE_FROM_VISITOR_FAILURE: '@chat/STORE_FROM_VISITOR_FAILURE',
  // FETCH_SESSIONS_WEBCHAT: '@chat/FETCH_SESSIONS_WEBCHAT',
  // NEW_VISITOR_WEBCHAT: '@chat/NEW_VISITOR_WEBCHAT',
  // UPDATED_VISITOR_WEBCHAT: '@chat/UPDATED_VISITOR_WEBCHAT',
  // ----->>>>
  // Actions relacionadas ao pagamento e carrinho
  // actions para variáveis visuais
  OPEN_PAYMENT_CHAT_MODAL: '@chat/OPEN_PAYMENT_CHAT_MODAL',
  CLOSE_PAYMENT_CHAT_MODAL: '@chat/CLOSE_PAYMENT_CHAT_MODAL',
  OPEN_CART_CHAT_MODAL: '@chat/OPEN_CART_CHAT_MODAL',
  CLOSE_CART_CHAT_MODAL: '@chat/CLOSE_CART_CHAT_MODAL',
  //SHOW_CATALOG_BUTTON: '@chat/SHOW_CATALOG_BUTTON',
  SHOW_CART_BUTTON: '@chat/SHOW_CART_BUTTON',
  OPEN_CATALOG_CAROUSEL: '@chat/OPEN_CATALOG_CAROUSEL',
  CLOSE_CATALOG_CAROUSEL: '@chat/CLOSE_CATALOG_CAROUSEL',

  //actions para requisição do pagamento
  PAYMENT_CHAT_REQUEST: '@chat/PAYMENT_CHAT_REQUEST',
  PAYMENT_CHAT_REQUEST_FAILURE: '@chat/PAYMENT_CHAT_REQUEST_FAILURE',
  PAYMENT_CHAT_REQUEST_SUCCESS: '@chat/PAYMENT_CHAT_REQUEST_SUCCESS',
  PAYMENT_CHAT_EDIT_PAYMENT: '@chat/PAYMENT_CHAT_EDIT_PAYMENT',
  PAYMENT_CHAT_CLEAR_PAYMENT: '@chat/PAYMENT_CHAT_CLEAR_PAYMENT',

  // actions para requisição dos itens do catalogo
  FETCH_CATALOG: '@chat/FETCH_CATALOG',
  FETCH_CATALOG_SUCCESS: '@chat/FETCH_CATALOG_SUCCESS',
  FETCH_MORE_PRODUCT_SUCCESS: '@chat/FETCH_MORE_PRODUCT_SUCCESS',
  FETCH_MORE_PRODUCT: '@chat/FETCH_MORE_PRODUCT',
  FETCH_CATALOG_FAILURE: '@chat/FETCH_CATALOG_FAILURE',
  FETCH_CATALOG_CATEGORIES: '@chat/FETCH_CATALOG_CATEGORIES',
  INCOMING_CATALOG_PRODUCT_UPDATE: '@chat/INCOMING_CATALOG_PRODUCT_UPDATE',
  INCOMING_CATALOG_PRODUCT_DELETED: '@chat/INCOMING_CATALOG_PRODUCT_DELETED',
  INCOMING_CATALOG_CATEGORY_UPDATE: '@chat/INCOMING_CATALOG_CATEGORY_UPDATE',
  INCOMING_CATALOG_CATEGORY_DELETED: '@chat/INCOMING_CATALOG_CATEGORY_DELETED',

  //actions do carrinho
  ADD_ITEM_TO_CART: '@chat/ADD_ITEM_TO_CART',
  UPDATE_AMOUNT_ITEM_FROM_CART: '@chat/UPDATE_AMOUNT_ITEM_FROM_CART',
  REMOVE_ITEM_FROM_CART: '@chat/REMOVE_ITEM_FROM_CART',
  UPDATE_CART_ADDRESS: '@chat/UPDATE_CART_ADDRESS',

  CHANGE_CHAT_CART: '@chat/CHANGE_CHAT_CART',
  FETCH_SHOPPING_CART_DETAIL_REQUEST:
    '@chat/FETCH_SHOPPING_CART_DETAIL_REQUEST',
  FETCH_SHOPPING_CART_DETAIL_FAILURE:
    '@chat/FETCH_SHOPPING_CART_DETAIL_FAILURE',

  CLEAR_SHOPPING_CART: '@chat/CLEAR_SHOPPING_CART',
  CHAT_SEND_ORDER: '@chat/CHAT_SEND_ORDER',
  UPDATE_CART_GATEWAY: '@chat/UPDATE_CART_GATEWAY',
  MARK_CHAT_SHOPPING_CART_SERVER_SYNCED:
    '@chat/MARK_CHAT_SHOPPING_CART_SERVER_SYNCED',
  CREATE_SHOPPING_CART_REQUEST: '@chat/CREATE_SHOPPING_CART_REQUEST',
  CREATE_SHOPPING_CART_FAILURE: '@chat/CREATE_SHOPPING_CART_FAILURE',

  UPDATE_SHOPPING_CART_REQUEST: '@chat/UPDATE_SHOPPING_CART_REQUEST',
  UPDATE_SHOPPING_CART_FAILURE: '@chat/UPDATE_SHOPPING_CART_FAILURE',

  ON_USER_LOGIN: '@chat/ON_USER_LOGIN',
  ON_USER_LOGOUT: '@chat/ON_USER_LOGOUT',

  DISTRIBUTE_CHAT: '@chat/DISTRIBUTE_CHAT',

  // Mobile
  SHOW_BLOCK_ONE: '@chat/SHOW_BLOCK_ONE',
  SHOW_BLOCK_TWO: '@chat/SHOW_BLOCK_TWO',
  SHOW_BLOCK_THREE: '@chat/SHOW_BLOCK_THREE',
  CLOSE_ALL_OPENED_CHATS: '@chat/CLOSE_ALL_OPENED_CHATS',

  RECEIVE_OPEN_CHAT: '@chat/RECEIVE_OPEN_CHAT',

  //instagram delete message
  ON_DELETED_INSTAGRAM_MESSAGE: '@chat/ON_DELETED_INSTAGRAM_MESSAGE',

  GET_PROTOCOL: '@chat/GET_PROTOCOL',
  GET_PROTOCOL_FAILURE: '@chat/GET_PROTOCOL_FAILURE',
  GET_PROTOCOL_SUCCESS: '@chat/GET_PROTOCOL_SUCCESS',

  //remove last user before redirect chat
  FINISHED_LAST_USER_BY_REDIRECT: '@chat/FINISHED_LAST_USER_BY_REDIRECT',

  //set add new company
  SET_ADD_NEW_COMPANY: '@chat/SET_ADD_NEW_COMPANY',

  //Opportunities and Tickets

  FETCH_OPPORTUNITIES_AND_TICKETS_REQUEST:'@chat/FETCH_OPPORTUNITIES_AND_TICKETS_REQUEST',

  FETCH_OPPORTUNITIES_AND_TICKETS_SUCCESS:'@chat/FETCH_OPPORTUNITIES_AND_TICKETS_SUCCESS',

  FETCH_OPPORTUNITIES_AND_TICKETS_FAILURE:'@chat/FETCH_OPPORTUNITIES_AND_TICKETS_FAILURE',
  
  PLAY_SOUND_NOTIFICATION: '@chat/PLAY_SOUND_NOTIFICATION',


  PLAY_SOUND_NOTIFICATION: '@chat/PLAY_SOUND_NOTIFICATION',
};

export function fetchOpportunitiesAndTicketsRequest(contact, all = false) { 
  const dataPayload = {
    contact: contact,
    all: all
  }
  return {
    type: actionNames.FETCH_OPPORTUNITIES_AND_TICKETS_REQUEST,
    payload: dataPayload,
  };
}


export function fetchOpportunitiesAndTicketsSuccess(data) { 
  return {
    type: actionNames.FETCH_OPPORTUNITIES_AND_TICKETS_SUCCESS,
    payload: data,
  };
}


export function fetchOpportunitiesAndTicketsFailure(contact) {
  return {
    type: actionNames.FETCH_OPPORTUNITIES_AND_TICKETS_FAILURE,
    payload: contact,
  };
}


export function onUserLogin(payload) {
  return {
    type: actionNames.ON_USER_LOGIN,
    payload,
  };
}

export function onUserLogout(payload) {
  return {
    type: actionNames.ON_USER_LOGOUT,
    payload,
  };
}

export function distributeChat(payload) {
  return {
    type: actionNames.DISTRIBUTE_CHAT,
    payload,
  };
}

export function storeUser(data) {
  return {
    type: actionNames.STORE_CHAT_USER,
    payload: data,
  };
}

export function removeUser(data) {
  return {
    type: actionNames.REMOVE_CHAT_USER,
    payload: data,
  };
}

export function storeContact(data) {
  return {
    type: actionNames.STORE_CHAT_CONTACT,
    payload: data,
  };
}

export function removeContact(data) {
  return {
    type: actionNames.REMOVE_CHAT_CONTACT,
    payload: data,
  };
}

/**
 * Abrir websocket
 */
export function openSocketRequest(tryAgain) {
  console.log('openSocketRequest');
  return {
    type: actionNames.OPEN_SOCKET_REQUEST,
    tryAgain,
  };
}

export function openSocketSuccess(socket) {
  return {
    type: actionNames.OPEN_SOCKET_SUCCESS,
    socket: socket,
  };
}

export function openSocketFailure(error) {
  return {
    type: actionNames.OPEN_SOCKET_FAILURE,
    error: error,
  };
}

/**
 * Guardar as configurações de usuário
 */
export function storeUserSettings(settings) {
  return {
    type: actionNames.STORE_USER_SETTINGS,
    payload: settings,
  };
}

/**
 * Buscar configurações
 */
export function fetchChatConfigRequest() {
  return {
    type: actionNames.FETCH_CHAT_CONFIG_REQUEST,
  };
}

export function fetchChatConfigSuccess(initialChatData) {
  return {
    type: actionNames.FETCH_CHAT_CONFIG_SUCCESS,
    payload: initialChatData,
  };
}

export function fetchChatConfigFailure(error) {
  return {
    type: actionNames.FETCH_CHAT_CONFIG_FAILURE,
    error: error,
  };
}

/**
 * Buscar a quantidade de não lidos
 *
 */
export function fetchCountNoReads() {
  return {
    type: actionNames.FETCH_COUNT_NO_READS,
  };
}

export function fetchCountNoReadsSuccess(data) {
  return {
    type: actionNames.FETCH_COUNT_NO_READS_SUCCESS,
    quantity: data.quantity,
  };
}

export function fetchCountNoReadsFailure(data) {
  return {
    type: actionNames.FETCH_COUNT_NO_READS_FAILURE,
    error: data.error,
  };
}

/**
 * Actions responsible for fetching users
 */
export function fetchUsersRequest() {
  return {
    type: actionNames.FETCH_USERS_REQUEST,
  };
}

export function fetchUsersSuccess(users) {
  return {
    type: actionNames.FETCH_USERS_SUCCESS,
    payload: users,
  };
}

export function fetchUsersFailure(error) {
  return {
    type: actionNames.FETCH_USERS_FAILURE,
    payload: error,
  };
}

/**
 *  ACTIONS RESPONSIBLE TO FETCH CHATS
 */
export function fetchChatRequest(event = null) {
  return {
    type: actionNames.FETCH_CHAT_REQUEST,
    payload: event,
  };
}

export function fetchChatSuccess(chats, nextPage) {
  return {
    type: actionNames.FETCH_CHAT_SUCCESS,
    chats,
    nextPage: nextPage || false,
  };
}

export function fetchChatFailure(error) {
  return {
    type: actionNames.FETCH_CHAT_FAILURE,
    error,
  };
}

/**
 * Actions repsonsible for fetching messages
 */
export function fetchMessagesRequest(chat, offLoading) {
  return {
    type: offLoading
      ? actionNames.FETCH_MESSAGES_REQUEST_ONE_CHAT
      : actionNames.FETCH_MESSAGES_REQUEST,
    chat,
  };
}

export function fetchMessagesSuccess(payload) {
  return {
    type: actionNames.FETCH_MESSAGES_SUCCESS,
    payload,
  };
}

export function oneChatLoaded() {
  return {
    type: actionNames.ONE_CHAT_LOADED,
  };
}

export function fetchMessagesFailure(error) {
  return {
    type: actionNames.FETCH_MESSAGES_FAILURE,
    error,
  };
}

/**
 * Actions repsonsible for fetching more messages (scrolling up)
 */
export function fetchMoreMessagesRequest(chat, lastMessageId) {
  return {
    type: actionNames.FETCH_MORE_MESSAGES_REQUEST,
    chat,
    lastMessageId,
  };
}

export function fetchMoreMessagesSuccess(payload) {
  return {
    type: actionNames.FETCH_MORE_MESSAGES_SUCCESS,
    payload,
  };
}

export function fetchMoreMessagesFailure(payload) {
  return {
    type: actionNames.FETCH_MORE_MESSAGES_FAILURE,
    payload,
  };
}

/**
 * Fetching more chats
 * @param {*} id
 */
export function fetchMoreChatsRequest(page, filters = [], search = '', screen) {
  return {
    type: actionNames.FETCH_MORE_CHATS_REQUEST,
    page,
    filters,
    search,
    screen: screen || 'all',
  };
}

export function updateCurrentPageCustomFilter(quantity) {
  return {
    type: actionNames.UPDATE_CURRENT_PAGE_CUSTOM_FILTER,
    quantity,
  };
}

export function fetchMoreChatsSuccess(payload, nextPage) {
  return {
    type: actionNames.FETCH_MORE_CHATS_SUCCESS,
    payload,
    nextPage: nextPage || false,
  };
}

export function fetchMoreChatsFailure(payload) {
  return {
    type: actionNames.FETCH_MORE_CHATS_FAILURE,
    payload,
  };
}
/**
 * Close chat
 */
export function closeChat(id) {
  return {
    type: actionNames.CLOSE_CHAT,
    id,
  };
}

/**
 * Open chat
 */
export function openChat(id) {
  return {
    type: actionNames.OPEN_CHAT,
    id,
  };
}


export function idSelectedChat(id) {
  return {
    type: actionNames.SELECTED_CHAT_ID,
    id,
  };
}

/**
 * Pegar protocolo
 */
export function getProtocol(chat_id) {
  return {
    type: actionNames.GET_PROTOCOL,
    chat_id
  };
}

export function getProtocolFailure(payload) {
  return {
    type: actionNames.GET_PROTOCOL_FAILURE,
    payload
  };
}
export function getProtocolSuccess(payload) {
  return {
    type: actionNames.GET_PROTOCOL_SUCCESS,
    payload
  };
}



/**
 * Ação para armazenar mensagem do textarea no estado
 */

export function createMessageTextArea() {
  return {
    type: actionNames.CREATE_MESSAGE_TEXTAREA,
  };
}

export function updateMessageTextArea(id, message) {
  return {
    type: actionNames.UPDATE_MESSAGE_TEXTAREA,
    id,
    message,
  };
}
/**
 * Ação para armazenar arquivos de upload dentro do estado
 */

export function createUploadList() {
  return {
    type: actionNames.CREATE_UPLOAD_LIST,
  };
}

export function addUploadToList(id, file, openDropzone) {
  if (!file) {
    return null;
  }
  if (openDropzone !== false) {
    openDropzone = true;
  }
  return {
    type: actionNames.ADD_UPLOAD_TO_LIST,
    id,
    file,
    openDropzone: openDropzone,
  };
}

export function updateStateUploadFilesMessage(data) {
  return {
    type: actionNames.UPDATE_STATE_UPLOAD_FILES_MESSAGE,
    payload: data,
  };
}
/**
 * Coloca as mídias dentro do array de mensagens
 */
export function updateMessageUrl(data) {
  return {
    type: actionNames.UPDATE_MESSAGE_URL,
    payload: data,
  };
}

export function removeItemUploadList(idChat, idItem) {
  return {
    type: actionNames.REMOVE_ITEM_UPLOAD_LIST,
    idChat,
    idItem,
  };
}

export function removeAllUploadsToList() {
  return {
    type: actionNames.CLEAR_LIST_UPLOAD,
  };
}

export function updateChatDropzone(id, value, clear) {
  return {
    type: actionNames.UPDATE_CHAT_DROPZONE,
    id,
    value,
    // Clear serve para limpar o array de uploads
    clear: clear || false,
  };
}

export function clearUploadsAfterSend(chatId) {
  return {
    type: actionNames.CLEAR_UPLOAD_AFTER_SEND,
    chatId,
  };
}

export function putMessageInStore(id, message) {
  return {
    type: actionNames.PUT_MESSAGE_IN_STORE,
    id,
    message,
  };
}

export function markAsRead(chat_id, sendBack=true) {
  return {
    sendBack,
    type: actionNames.MARK_READ,
    chat_id,
  };
}

/**
 * Open finish chat modal
 */
export function openFinishChatModal() {
  return {
    type: actionNames.OPEN_FINISH_CHAT_MODAL,
  };
}

export function closeFinishChatModal() {
  return {
    type: actionNames.CLOSE_FINISH_CHAT_MODAL,
  };
}

/**
 * Tags
 */
export function openTagChatModal() {
  return {
    type: actionNames.OPEN_TAG_CHAT_MODAL,
  };
}

export function getTags() {
  return {
    type: actionNames.GET_TAGS,
  };
}

export function closeTagChatModal() {
  return {
    type: actionNames.CLOSE_TAG_CHAT_MODAL,
  };
}

export function insertTagToContact(payload) {
  return {
    type: actionNames.INSERT_TAG_TO_CONTACT,
    payload,
  };
}

export function removeTagFromContact(payload) {
  return {
    type: actionNames.REMOVE_TAG_FROM_CONTACT,
    payload,
  };
}

export function insertTagToContactSubmit(payload) {
  return {
    type: actionNames.INSERT_TAG_TO_CONTACT_SUBMIT,
    payload,
  };
}

export function insertTagToContactRequest(payload) {
  return {
    type: actionNames.INSERT_TAG_TO_CONTACT_REQUEST,
    payload,
  };
}

export function insertTagToContactSuccess(payload) {
  return {
    type: actionNames.INSERT_TAG_TO_CONTACT_SUCCESS,
    payload,
  };
}

export function insertTagToContactFailure(payload) {
  return {
    type: actionNames.INSERT_TAG_TO_CONTACT_FAILURE,
    payload,
  };
}

export function removeTagFromContactRequest(payload) {
  return {
    type: actionNames.REMOVE_TAG_FROM_CONTACT_REQUEST,
    payload,
  };
}

export function removeTagFromContactSuccess(payload) {
  return {
    type: actionNames.REMOVE_TAG_FROM_CONTACT_SUCCESS,
    payload,
  };
}

export function incomingTagCreated(payload) {
  return {
    type: actionNames.INCOMING_TAG_CREATED,
    payload,
  };
}

export function incomingTagDeleted(payload) {
  return {
    type: actionNames.INCOMING_TAG_DELETED,
    payload,
  };
}

/**
 * Open transfer chat modal
 */
export function openTransferChatModal() {
  return {
    type: actionNames.OPEN_TRANSFER_CHAT_MODAL,
  };
}

export function openTransferChatModalSuccess(payload) {
  return {
    type: actionNames.OPEN_TRANSFER_CHAT_MODAL_SUCCESS,
    payload,
  };
}

export function closeTransferChatModal() {
  return {
    type: actionNames.CLOSE_TRANSFER_CHAT_MODAL,
  };
}

/**
 * Open transfer chat request
 */
export function transferChatRequest(data) {
  return {
    type: actionNames.TRANSFER_CHAT_REQUEST,
    data,
  };
}

export function transferChatSuccess(payload) {
  return {
    type: actionNames.TRANSFER_CHAT_SUCCESS,
    payload,
  };
}

export function transferChatFailure(error) {
  return {
    type: actionNames.TRANSFER_CHAT_FAILURE,
    error,
  };
}

/**
 * Finish chat
 * @param {*}
 */
export function finishChatRequest(chat_id, channel_id) {
  return {
    type: actionNames.FINISH_CHAT_REQUEST,
    chat_id,
    channel_id
  };
}

export function finishChatSuccess(payload) {
  return {
    type: actionNames.FINISH_CHAT_SUCCESS,
    payload,
  };
}

export function finishChatFailure(error) {
  return {
    type: actionNames.FINISH_CHAT_FAILURE,
    error,
  };
}

/**
 *
 * @param {*} showDetails
 */
export function showDetailsComponent(showDetails) {
  return {
    type: actionNames.SHOW_DETAILS_COMPONENT,
    showDetails,
    openTagList: false,
  };
}

export function openTagInDetails(openTagList) {
  return {
    type: actionNames.OPEN_TAG_IN_DETAILS,
    openTagList,
  };
}

/**
 *  Busca a llista de Operadores
 */
export function fetchOperatorsListRequest(operatorId, customerId) {
  return {
    type: actionNames.FETCH_OPERATORS_LIST_REQUEST,
    operatorId: operatorId,
    customerId: customerId,
  };
}

export function fetchOperatorsListSuccess(payload) {
  return {
    type: actionNames.FETCH_OPERATORS_LIST_SUCCESS,
    payload,
  };
}

export function fetchOperatorsListFailure(error) {
  return {
    type: actionNames.FETCH_OPERATORS_LIST_FAILURE,
    error,
  };
}

/**
 * Envia mensagem de texto
 */

export function addMessage(data) {
  return {
    type: actionNames.ADD_MESSAGE,
    data,
  };
}

export function sendMessage(data) {
  return {
    type: actionNames.SEND_MESSAGE,
    data,
  };
}

export function sendMessageSuccess(payload) {
  return {
    type: actionNames.SEND_MESSAGE_SUCCESS,
    payload,
  };
}

export function sendMessageFailure(payload) {
  return {
    type: actionNames.SEND_MESSAGE_FAILURE,
    payload,
  };
}

/**
 * Recebe o ACK de uma mensagem
 * @param {*} payload
 */
export function receiveAck(payload) {
  return {
    type: actionNames.RECEIVE_ACK,
    payload,
  };
}

export function receiveOpenChat(payload) {
  return {
    type: actionNames.RECEIVE_OPEN_CHAT,
    payload,
  };
}

/**
 * Recebe uma nova mensagem
 * @param {*} payload
 */
export function receiveMessage(payload) {
  return {
    type: actionNames.RECEIVE_MESSAGE,
    payload,
  };
}

export function resetCounterNewMessage(chatId) {
  return {
    type: actionNames.RESET_COUNTER_NEW_MESSAGE,
    chatId,
  };
}

/**
 * Armazena a mensagem recebida
 * @param {*} payload
 */
export function storeReceivedMessage(payload) {
  return {
    type: actionNames.STORE_RECEIVED_MESSAGE,
    payload,
  };
}

export function storeReceivedChatWithMessage(payload) {
  return {
    type: actionNames.STORE_RECEIVED_CHAT_WITH_MESSAGE,
    payload,
  };
}

/**
 * Busca contact_external do contato clicado
 */
export function fetchContactExternal(chat_id) {
  return {
    type: actionNames.FETCH_CONTACT_EXTERNAL,
    chat_id,
  };
}

export function fetchContactExternalSuccess(payload) {
  return {
    type: actionNames.FETCH_CONTACT_EXTERNAL_SUCCESS,
    payload,
  };
}

/**
 * Seleciona por qual canal o usuário enviará a mensagem
 * @param {*} payload
 */
export function selectChannel(payload) {
  return {
    type: actionNames.SELECT_CHANNEL,
    payload,
  };
}

/**
 * Seleciona com qual o contato externo dentre os contatos o usuário irá conversar
 * @param {*} payload
 */
export function selectContactExternal(external_id, channel_type) {
  return {
    type: actionNames.SELECT_CONTACT_EXTERNAL,
    external_id,
    channel_type,
  };
}

/***
 * AÇÕES RELACIONADAS AOS CONTATOS
 *
 * MUDA A NAVEGAÇÃO QUE ESTÁ ATIVA NAS TABS CHAT /CONTATOS
 */

export function openChatTab() {
  return {
    type: actionNames.OPEN_CHAT_TAB,
  };
}

export function openContactTab() {
  return {
    type: actionNames.OPEN_CONTACT_TAB,
  };
}

export function openInProgressTab() {
  return {
    type: actionNames.OPEN_INPROGRESS_CHATS_TAB,
  };
}

export function openNoAttendantTab() {
  return {
    type: actionNames.OPEN_NOATTENDANT_TAB,
  };
}

export function openUnReadsTab() {
  return {
    type: actionNames.OPEN_UNREADS_TAB,
  };
}

// Essa ação buscará os contatos dentro do Laravel
export function fetchPendencesChatRequest() {
  return {
    type: actionNames.FETCH_PENDENCES_REQUEST,
  };
}

export function fetchPendencesChatSuccess(payload) {
  return {
    type: actionNames.FETCH_PENDENCES_CHATS_SUCCESS,
    payload,
  };
}

export function fetchPendencesChatFailure(payload) {
  return {
    type: actionNames.FETCH_PENDENCES_CHATS_FAILURE,
    payload,
  };
}

// Essa ação buscará o contato dentro do Laravel
export function fetchUniqueChatRequest(id) {
  return {
    type: actionNames.FETCH_UNIQUE_CHAT_REQUEST,
    id,
  };
}
export function fetchUniqueChatFailure(data) {
  return {
    type: actionNames.FETCH_UNIQUE_CHAT_FAILURE,
    data,
  };
}
export function fetchUniqueChatSuccess(data) {
  return {
    type: actionNames.FETCH_UNIQUE_CHAT_SUCCESS,
    data,
  };
}
export function fetchUniqueChat(data) {
  return {
    type: actionNames.FETCH_UNIQUE_CHAT,
    data,
  };
}
export function setupContactContent() {
  return {
    type: actionNames.SETUP_INITIAL_CONTACT_CONTENT,
  };
}

export function setOpenAddContact(value) {
  return {
    type: actionNames.SET_OPEN_ADD_CONTACT,
    value,
  };
}

export function setAddOrganizationForm(value) {
  return {
    type: actionNames.SET_ADD_NEW_COMPANY,
    value,
  };
}

export function setAddCardContact(payload) {
  return {
    type: actionNames.SET_ADD_CARD_CONTACT,
    payload,
  };
}

export function unsetAddCardContact() {
  return {
    type: actionNames.UNSET_ADD_CARD_CONTACT,
  };
}

export function setOpenImportContact(value) {
  return {
    type: actionNames.SET_OPEN_IMPORT_CONTACT,
    value,
  };
}

export function setOpenEditContact(value, data) {
  data = data || null;
  return {
    type: actionNames.SET_OPEN_EDIT_CONTACT,
    // value = true or false
    value,
    data,
  };
}

// Ação que busca todos os contatos de um usuário e de uma empresa
export function fetchAllContacts() {
  return {
    type: actionNames.FETCH_CONTACTS_REQUEST,
  };
}

export function fetchAllContactsSuccess(data) {
  return {
    type: actionNames.FETCH_CONTACTS_SUCCESS,
    data,
  };
}

export function fetchAllContactsFailure(error) {
  return {
    type: actionNames.FETCH_CONTACTS_FAILURE,
    error: error,
  };
}

/**
 * Ações para buscar mais contatos
 * @param {*} id
 */
export function fetchMoreContactsRequest(page, filters = [], search = '') {
  return {
    type: actionNames.FETCH_MORE_CONTACTS_REQUEST,
    page,
    filters,
    search,
  };
}

export function fetchMoreContactsSuccess(payload) {
  return {
    type: actionNames.FETCH_MORE_CONTACTS_SUCCESS,
    payload,
  };
}

export function fetchMoreContactsFailure(payload) {
  return {
    type: actionNames.FETCH_MORE_CONTACTS_FAILURE,
    payload,
  };
}

// Ação que Cria um novo contato
// bool é para adicionar ao Portfolio
export function createContactRequest(data, bool) {
  return {
    type: actionNames.CREATE_CONTACT_REQUEST,
    data,
    bool,
  };
}

export function createContactSuccess(data) {
  return {
    type: actionNames.CREATE_CONTACT_SUCCESS,
    data,
  };
}

export function createContactFailure(error) {
  return {
    type: actionNames.CREATE_CONTACT_FAILURE,
    error: error,
  };
}

// Ação que atualiza um contato
export function updateContactRequest(data) {
  return {
    type: actionNames.UPDATE_CONTACT_REQUEST,
    data,
  };
}

export function updateContactSuccess(data) {
  return {
    type: actionNames.UPDATE_CONTACT_SUCCESS,
    data,
  };
}

export function updateContactFailure(error) {
  return {
    type: actionNames.UPDATE_CONTACT_FAILURE,
    error: error,
  };
}

// Ação que remove um contato external
export function deleteContactExternalRequest(data) {
  return {
    type: actionNames.DELETE_CONTACT_EXTERNAL_REQUEST,
    data,
  };
}

export function deleteContactExternalSuccess(data) {
  return {
    type: actionNames.DELETE_CONTACT_EXTERNAL_SUCCESS,
    data,
  };
}

export function deleteContactExternalFailure(error) {
  return {
    type: actionNames.DELETE_CONTACT_EXTERNAL_FAILURE,
    error: error,
  };
}

// Ações de Retorno Busca por Contatos
export function contactSearchSuccess(data) {
  return {
    type: actionNames.CONTACT_SEARCH_SUCCESS,
    data,
  };
}

export function contactSearchFailure(error) {
  return {
    type: actionNames.CONTACT_SEARCH_FAILURE,
    error: error,
  };
}

/**Busca de contatos que serão vinculados */
export function mergeContactSearch(payload) {
  return {
    type: actionNames.MERGE_CONTACT_SEARCH,
    payload,
  };
}
export function mergeContactSearchSuccess(payload) {
  return {
    type: actionNames.MERGE_CONTACT_SEARCH_SUCCESS,
    payload,
  };
}
export function mergeContactSearchFailure(payload) {
  return {
    type: actionNames.MERGE_CONTACT_SEARCH_FAILURE,
    payload,
  };
}

export function mergeContacts(contact_id_from, contact_id_to) {
  return {
    type: actionNames.MERGE_CONTACTS,
    payload: { contact_id_from, contact_id_to },
  };
}

export function mergeContactsSuccess(payload) {
  return {
    type: actionNames.MERGE_CONTACTS_SUCCESS,
    payload,
  };
}
export function mergeContactsFailure(payload) {
  return {
    type: actionNames.MERGE_CONTACTS_FAILURE,
    payload,
  };
}
export function unmergeContacts(contact_id_from, contact_id_to) {
  return {
    type: actionNames.UNMERGE_CONTACTS,
    payload: { contact_id_from, contact_id_to },
  };
}
export function unmergeContactsSuccess(payload) {
  return {
    type: actionNames.UNMERGE_CONTACTS_SUCCESS,
    payload,
  };
}
export function unmergeContactsFailure(payload) {
  return {
    type: actionNames.UNMERGE_CONTACTS_FAILURE,
    payload,
  };
}
/**
 *  FIM DAS AÇÕES PARA CONTATOS
 */

/**
 * Incoming Channel Events
 */
export function storeChannel(payload) {
  return {
    type: actionNames.STORE_CHANNEL,
    payload,
  };
}
export function updateChannel(payload) {
  return {
    type: actionNames.UPDATE_CHANNEL,
    payload,
  };
}
export function deleteChannel(payload) {
  return {
    type: actionNames.DELETE_CHANNEL,
    payload,
  };
}

/**
 * Filtros
 */
export function toggleFilterMenu(filterName) {
  return {
    type: actionNames.TOGGLE_FILTER_MENU,
    filterName,
  };
}

export function submitFilter(payload, screen) {
  return {
    type: actionNames.SUBMIT_FILTER,
    payload,
    screen: screen || 'all',
  };
}

export function updateFilters(payload) {
  return {
    type: actionNames.UPDATE_FILTERS,
    payload,
  };
}

export function removeFilter(payload) {
  return {
    type: actionNames.REMOVE_FILTER,
    payload,
  };
}

export function clearFilter(payload) {
  return {
    type: actionNames.CLEAR_FILTER,
    payload,
  };
}

export function clearCustomFilterPage() {
  return {
    type: actionNames.CLEAR_CUSTOM_FILTER_PAGE,
  };
}

/**
 * Atualiza o pesquisar
 */

export function search(payload, whoSendAction, screen) {
  return {
    type: actionNames.SEARCH,
    payload,
    whoSendAction,
    screen: screen || 'all',
  };
}

export function putResultsInSearchChats(chats) {
  return {
    type: actionNames.PUT_RESULTS_IN_SEARCH_CHATS,
    chats,
  };
}

export function clearResultsInSearchChats() {
  return {
    type: actionNames.CLEAR_RESULTS_IN_SEARCH_CHATS,
  };
}

export function updateChatSearch(payload) {
  return {
    type: actionNames.UPDATE_CHAT_SEARCH,
    payload,
  };
}
export function updateContactSearch(payload) {
  return {
    type: actionNames.UPDATE_CONTACT_SEARCH,
    payload,
  };
}

/**
 * Inicializa o loading ao bsucar mais chats
 */
export function startFetchingChatsLoading(payload) {
  return {
    type: actionNames.START_FETCHING_CHATS_SEARCH_LOADING,
    payload,
  };
}

/**
 * Inicializa o loading ao bsucar mais contatos
 */
export function startFetchingContactsLoading(payload) {
  return {
    type: actionNames.START_FETCHING_CONTACTS_LOADING,
    payload,
  };
}

/**
 * busca as mensagens rapidas cadastradas
 */
export function fetchQuickMessages() {
  return {
    type: actionNames.FETCH_QUICK_MESSAGES,
  };
}
export function fetchQuickMessagesSuccess(payload) {
  return {
    type: actionNames.FETCH_QUICK_MESSAGES_SUCCESS,
    payload,
  };
}
export function fetchQuickMessagesFailure(payload) {
  return {
    type: actionNames.FETCH_QUICK_MESSAGES_FAILURE,
    payload,
  };
}

export function toggleQuickMessagesSelector(payload = null) {
  return {
    type: actionNames.TOGGLE_QUICK_MESSAGES_SELECTOR,
    payload,
  };
}
export function navQuickMessagesSelector(dir) {
  return {
    type: actionNames.NAV_QUICK_MESSAGES_SELECTOR,
    dir,
  };
}
export function searchQuickMessages(term, isFlagQuickMessageV2Active = false) {
  return {
    type: actionNames.SEARCH_QUICK_MESSAGES,
    term: term || '',
    isFlagQuickMessageV2Active,
  };
}
export function createQuickMessage(payload) {
  return {
    type: actionNames.CREATE_QUICK_MESSAGE,
    payload,
  };
}
export function createQuickMessageSuccess(payload) {
  return {
    type: actionNames.CREATE_QUICK_MESSAGE_SUCCESS,
    payload,
  };
}
export function createQuickMessageFailure(payload) {
  return {
    type: actionNames.CREATE_QUICK_MESSAGE_FAILURE,
    payload,
  };
}
export function updateQuickMessage(payload) {
  return {
    type: actionNames.UPDATE_QUICK_MESSAGE,
    payload,
  };
}
export function updateQuickMessageSuccess(payload) {
  return {
    type: actionNames.UPDATE_QUICK_MESSAGE_SUCCESS,
    payload,
  };
}
export function updateQuickMessageFailure(payload) {
  return {
    type: actionNames.UPDATE_QUICK_MESSAGE_FAILURE,
    payload,
  };
}
export function cancelEditQuickMessage() {
  return {
    type: actionNames.CANCEL_EDIT_QUICK_MESSAGE,
  };
}
export function cancelDeleteQuickMessage() {
  return {
    type: actionNames.CANCEL_DELETE_QUICK_MESSAGE,
  };
}
export function deleteQuickMessage(payload) {
  return {
    type: actionNames.DELETE_QUICK_MESSAGE,
    payload,
  };
}
export function alertDeleteQuickMessage(payload) {
  return {
    type: actionNames.ALERT_DELETE_QUICK_MESSAGE,
    payload,
  };
}
export function editQuickMessage(payload) {
  return {
    type: actionNames.EDIT_QUICK_MESSAGE,
    payload,
  };
}
export function createdQuickMessage(payload) {
  return {
    type: actionNames.CREATED_QUICK_MESSAGE,
    payload,
  };
}
export function updatedQuickMessage(payload) {
  return {
    type: actionNames.UPDATED_QUICK_MESSAGE,
    payload,
  };
}
export function deletedQuickMessage(payload) {
  return {
    type: actionNames.DELETED_QUICK_MESSAGE,
    payload,
  };
}
export function updateSettings(payload) {
  return {
    type: actionNames.UPDATE_SETTINGS,
    payload,
  };
}

export function updateCustomerOnboarding(payload) {
  return {
    type: actionNames.UPDATE_CUSTOMER_ONBOARDING,
    payload,
  };
}

export function sendFilesMessage(data) {
  return {
    type: actionNames.SEND_FILES_MESSAGE,
    payload: data,
  };
}

export function uploadFileMessage(data) {
  return {
    type: actionNames.UPLOAD_FILE_MESSAGE,
    payload: data,
  };
}

export function replyingMessage(data) {
  return {
    type: actionNames.REPLYING_MESSAGE,
    payload: data,
  };
}

export function clearReplyingMessage(data) {
  return {
    type: actionNames.CLEAR_REPLYING_MESSAGE,
    payload: data,
  };
}

/*
export function prepareRecorder(payload) {
  return {
    type: actionNames.PREPARE_RECORDER,
    payload,
  };
}*/
// export function updateFilterHistory(payload) {
//   return {
//     type: actionNames.UPDATE_FILTER_HISTORY,
//     payload,
//   };
// }

// /**
//  * Armazena a mensagem que não possui informação de chat ainda
//  * @param {*} payload
//  */
// export function storeMessageWithoutChat(payload) {
//   return {
//     type: actionNames.STORE_MESSAGE_WITHOUT_CHAT,
//     payload,
//   };
// }

// /**
//  * Busca a mensagem que está sem chat e armazena,
//  * tanto o chat quanto a mensagem no state
//  */
// export function mergeChatWithMessage(payload) {
//   return {
//     type: actionNames.MERGE_CHAT_WITH_MESSAGE,
//     payload,
//   };
// }

// /**WEBCHAT */
// export function startVisitorsService() {
//   return {
//     type: actionNames.START_VISITORS_SERVICE,
//   };
// }
// export function startVisitorsServiceFailure() {
//   return {
//     type: actionNames.START_VISITORS_SERVICE_FAILURE,
//   };
// }
// export function startVisitorsServiceSuccess(payload) {
//   return {
//     type: actionNames.START_VISITORS_SERVICE_SUCCESS,
//     payload,
//   };
// }
// export function storeFromVisitor(payload) {
//   return {
//     type: actionNames.STORE_FROM_VISITOR,
//     payload,
//   };
// }
// export function storeFromVisitorSuccess(payload) {
//   return {
//     type: actionNames.STORE_FROM_VISITOR_SUCCESS,
//     payload,
//   };
// }
// export function storeFromVisitorFailure(payload) {
//   return {
//     type: actionNames.STORE_FROM_VISITOR_FAILURE,
//     payload,
//   };
// }
// export function fetchSessionsWebchat(payload) {
//   return {
//     type: actionNames.FETCH_SESSIONS_WEBCHAT,
//     payload,
//   };
// }
// export function newVisitorWebchat(payload) {
//   return {
//     type: actionNames.NEW_VISITOR_WEBCHAT,
//     payload,
//   };
// }
// export function updatedVisitorWebchat(payload) {
//   return {
//     type: actionNames.UPDATED_VISITOR_WEBCHAT,
//     payload,
//   };
// }

/**
 * Actions relacionadas a cobrança e carrinho
 */

//abrir e fechar modal de cobrança
export function openPaymentChatModal(chatId) {
  return {
    type: actionNames.OPEN_PAYMENT_CHAT_MODAL,
    chatId,
  };
}

export function closePaymentChatModal() {
  return {
    type: actionNames.CLOSE_PAYMENT_CHAT_MODAL,
  };
}

export function paymentChatRequest(payload) {
  return {
    type: actionNames.PAYMENT_CHAT_REQUEST,
    payload,
  };
}
export function paymentChatRequestFailure(payload) {
  return {
    type: actionNames.PAYMENT_CHAT_REQUEST_FAILURE,
    payload,
  };
}

export function paymentChatRequestSuccess(payload) {
  return {
    type: actionNames.PAYMENT_CHAT_REQUEST_SUCCESS,
    payload,
  };
}

export function paymentChatEditPayment(chatId) {
  return {
    type: actionNames.PAYMENT_CHAT_EDIT_PAYMENT,
    chatId,
  };
}

export function paymentChatClearPayment(chatId) {
  return {
    type: actionNames.PAYMENT_CHAT_CLEAR_PAYMENT,
    chatId,
  };
}

export function openCartChatModal() {
  return {
    type: actionNames.OPEN_CART_CHAT_MODAL,
  };
}

export function closeCartChatModal() {
  return {
    type: actionNames.CLOSE_CART_CHAT_MODAL,
  };
}

export function showCartButton(value = true) {
  return {
    type: actionNames.SHOW_CART_BUTTON,
    value,
  };
}

export function openCatalogCarousel() {
  return {
    type: actionNames.OPEN_CATALOG_CAROUSEL,
  };
}

export function closeCatalogCarousel() {
  return {
    type: actionNames.CLOSE_CATALOG_CAROUSEL,
  };
}

export function fetchCatalog() {
  return {
    type: actionNames.FETCH_CATALOG,
  };
}
export function fetchCatalogSuccess(payload) {
  return {
    type: actionNames.FETCH_CATALOG_SUCCESS,
    payload,
  };
}

export function fetchMoreProductSuccess(payload) {
  return {
    type: actionNames.FETCH_MORE_PRODUCT_SUCCESS,
    payload,
  };
}

export function fetchMoreProduct(payload) {
  return {
    type: actionNames.FETCH_MORE_PRODUCT,
    payload,
  };
}

export function fetchCatalogFailure(payload) {
  return {
    type: actionNames.FETCH_CATALOG_FAILURE,
    payload,
  };
}

export function fetchCatalogCategories() {
  return {
    type: actionNames.FETCH_CATALOG_CATEGORIES,
  };
}

export function incomingCatalogProductUpdate(payload) {
  return {
    type: actionNames.INCOMING_CATALOG_PRODUCT_UPDATE,
    payload,
  };
}

export function incomingCatalogCategoryUpdate(payload) {
  return {
    type: actionNames.INCOMING_CATALOG_CATEGORY_UPDATE,
    payload,
  };
}

export function incomingCatalogProductDeleted(payload) {
  return {
    type: actionNames.INCOMING_CATALOG_PRODUCT_DELETED,
    payload,
  };
}

export function incomingCatalogCategoryDeleted(payload) {
  return {
    type: actionNames.INCOMING_CATALOG_CATEGORY_DELETED,
    payload,
  };
}

export function changeChatCart(chatId, data) {
  return {
    type: actionNames.CHANGE_CHAT_CART,
    chatId,
    data,
  };
}

export function addItemToCart(chatId, productId) {
  return {
    type: actionNames.ADD_ITEM_TO_CART,
    chatId,
    productId,
  };
}

export function removeItemFromCart(chatId, productId) {
  return {
    type: actionNames.REMOVE_ITEM_FROM_CART,
    chatId,
    productId,
  };
}

export function updateAmountItemFromCart(chatId, productId, amount) {
  return {
    type: actionNames.UPDATE_AMOUNT_ITEM_FROM_CART,
    chatId,
    productId,
    amount,
  };
}

export function updateCartGateway(chatId, cartId, newGatewayId) {
  return {
    type: actionNames.UPDATE_CART_GATEWAY,
    chatId,
    cartId,
    newGatewayId,
  };
}

export function fetchShoppingCartDetail(id) {
  return {
    type: actionNames.FETCH_SHOPPING_CART_DETAIL_REQUEST,
    payload: id,
  };
}

export function fetchShopppingCartDetailFailure(error) {
  return {
    type: actionNames.FETCH_SHOPPING_CART_DETAIL_FAILURE,
    error: error,
  };
}

export function markChatShopppingCartServerSynced(chat_id, cart_id) {
  return {
    type: actionNames.MARK_CHAT_SHOPPING_CART_SERVER_SYNCED,
    chat_id,
    cart_id,
  };
}

export function createShopppingCart(data) {
  return {
    type: actionNames.CREATE_SHOPPING_CART_REQUEST,
    payload: data,
  };
}

export function createShopppingCartFailure(payload = null) {
  return {
    type: actionNames.CREATE_SHOPPING_CART_FAILURE,
    payload,
  };
}

export function updateShopppingCart(chat_id, order_id, order_data) {
  return {
    type: actionNames.UPDATE_SHOPPING_CART_REQUEST,
    chat_id,
    order_id,
    order_data,
  };
}

export function updateShopppingCartFailure(chat_id, cart_id) {
  return {
    type: actionNames.UPDATE_SHOPPING_CART_FAILURE,
    chat_id,
    cart_id,
  };
}

export function updateCartAddress(chatId, address, shipping_cost) {
  return {
    type: actionNames.UPDATE_CART_ADDRESS,
    chatId,
    address,
    shipping_cost,
  };
}

export function sendOrder(
  chat_id,
  order_id,
  type,
  message = null,
  integration_id
) {
  return {
    type: actionNames.CHAT_SEND_ORDER,
    chat_id,
    order_id,
    order_type: type,
    message,
    integration_id,
  };
}

export function clearShoppingCart(chat_id, cart_id) {
  return {
    type: actionNames.CLEAR_SHOPPING_CART,
    chat_id,
    cart_id,
  };
}

/* MOBILE */

export function showBlockOne() {
  return {
    type: actionNames.SHOW_BLOCK_ONE,
  };
}

export function showBlockTwo() {
  return {
    type: actionNames.SHOW_BLOCK_TWO,
  };
}

export function showBlockThree() {
  return {
    type: actionNames.SHOW_BLOCK_THREE,
  };
}

// Mostly used on mobile devices where user has only one screen at a time.
export function closeAllOpenedChats() {
  return {
    type: actionNames.CLOSE_ALL_OPENED_CHATS,
  };
}

export function onDeletedInstagramMessage(payload) {
  //console.log('on delete message action is ON');
  return {
    type: actionNames.ON_DELETED_INSTAGRAM_MESSAGE,
    payload,
  };
}

// Remove last user before redirect chat.
export function finishedLastUserByRedirect(payload) {
  return {
    type: actionNames.FINISHED_LAST_USER_BY_REDIRECT,
    payload,
  };
}

export function playSoundNotification(payload) {
  return {
    type: actionNames.PLAY_SOUND_NOTIFICATION,
    payload,
  };
}
