import {
  put,
  all,
  takeLatest,
  select,
  call,
  takeEvery,
} from 'redux-saga/effects';
import WebSocketService from '../../../lib/WebSocketService';
import axios from 'axios';
import { findChannelId, findSelectedExternalId } from './functions';

import ProductService from '../../../services/shopping/product';
import OrderService from '../../../services/shopping/order';

import { toast } from 'react-toastify';

import {
  openSocketSuccess,
  openSocketFailure,
  actionNames,
  updateFilters,
  insertTagToContactRequest,
  removeTagFromContactSuccess,
  startFetchingChatsLoading,
  startFetchingContactsLoading,
  updateChatSearch,
  updateContactSearch,
  clearResultsInSearchChats,
  putResultsInSearchChats,
  updateStateUploadFilesMessage,
  updateMessageUrl,
  mergeContactSearchFailure,
  mergeContactsFailure,
  unmergeContactsFailure,
  // startVisitorsServiceFailure,
  // storeFromVisitorFailure,
  fetchChatConfigFailure,
  fetchMoreChatsFailure,
  clearUploadsAfterSend,
  //pagamento
  paymentChatRequestFailure,
  paymentChatRequestSuccess,
  fetchCatalogFailure,
  fetchCatalogCategoriesFailure,
  fetchCatalogSuccess,
  fetchMoreProductSuccess,
  fetchCatalogCategoriesSucfetchCatalogSuccess,
  fetchCatalogCategoriesSuccess,
  createShopppingCartFailure,
  updateShopppingCartSuccess,
  updateShopppingCartFailure,
  fetchShopppingCartDetailFailure,
  changeChatCart,
  showCartButton,
  fetchCatalog,
  addMessage as storageMessage,
  sendMessage as sendMessageToServer,
  closePaymentChatModal,
  clearShoppingCart,
  updateCurrentPageCustomFilter,
  fetchOpportunitiesAndTicketsFailure,
  fetchOpportunitiesAndTicketsSuccess,
} from './actions';
import ChatListener from '../../../listeners/ChatListener';
import store from '../../../store';

import { LIMIT_CHATS_PER_PAGE } from '../../../config/chat';
import { applyChatFilters } from '../../../utils/chat/applyFilters';
import getOpportunitiesAndTickets from 'src/components/tools/integrations/crmRequests/getOpportunitiesAndTickets';

/**
 * conect and retry socket passivo
 */

function connectSocketPassivo(websocketPassivo, retries = 0){
  try{
    websocketPassivo.onReceiveMessage(ChatListener);

    websocketPassivo.connect();
  } catch(error) {

    switch (retries) {
      case 0:
      case 1:
      case 2:
        return connectSocketPassivo(websocketPassivo, retries+1);
      case 3:
      case 4:
      case 5:
      case 6:
        setTimeout(() => {
          return connectSocketPassivo(websocketPassivo, retries+1);
        }, 30000);
        break;
      case 7:
      case 8:
      case 9:
      case 10:
        setTimeout(() => {
          return connectSocketPassivo(websocketPassivo, retries+1);
        }, 45000);
        break;
      default:
        throw new Error('Failed to connect with websocket passivo.');
    }
  }
}
/**
 * Open the websocket connection
 */
function* openSocketRequest() {
  try{
    const spa_api_state = (state) => state.auth.access;
    const general_state = (state) => state.general;

    const spa_api = yield select(spa_api_state);
    const general_data = yield select(general_state);

    const websocketHost = process.env?.REACT_APP_DISPATCH_WEBSOCKET_URL;

    const websocketPassivo = yield new WebSocketService(
      `${websocketHost}?auth_token=${spa_api.token}&customer_id=${general_data.current_customer_id}`
    );

    connectSocketPassivo(websocketPassivo);

    //Websocket Ativo - Envia eventos para o laravel
    const websocketAtivo = yield new WebSocketService(
      `${process.env?.REACT_APP_WS_HOST_ATIVO}?auth_token=${spa_api.token}&customer_id=${general_data.current_customer_id}`
    );
    websocketAtivo.onReceiveMessage(ChatListener);

    websocketAtivo.onOpen(() => {
      store.dispatch(openSocketSuccess(websocketAtivo));
    });

    websocketAtivo.onClose((event) => {
      //Só vai direcionar para a tela de login se o websocket service verificar que o token não é valido.
      if (event.reason === 'unauthorized')
        return (window.location.href = '/logout');
    });

    websocketAtivo.connect();

  } catch (error) {
    yield put(openSocketFailure(error));
  }

}

/**
 * Fetch data from chat configuration
 */
function* fetchChatConfig() {
  try {
    const wsState = (state) => state.chat.socket;
    const userState = (state) => state.chat.config.user.id;

    const websocket = yield select(wsState);
    const user_id = yield select(userState);

    if (!websocket || !user_id) {
      return;
    }

    let data = {};
    if (localStorage.getItem('subscription_push')) {
      data.subscription_push = JSON.parse(
        localStorage.getItem('subscription_push')
      );

      // Para o push notification conseguir identificar o subscription que precisa ser ativado/desativado
      if (
        data.subscription_push &&
        localStorage.getItem('navigator_id_internal')
      ) {
        data.subscription_push.navigatorIdInternal = localStorage.getItem(
          'navigator_id_internal'
        );
      }
    }

    yield websocket.sendMessage(
      JSON.stringify({
        subject: 'start_chat_config',
        data,
      })
    );
  } catch (error) {
    // console.error(error);
    store.dispatch(
      fetchChatConfigFailure({ msg: 'Erro ao solicitar configurações' })
    );
  }
}

/**
 * Fetch no reads
 */
function* fetchCountNoReads() {
  try {
    const wsState = (state) => state.chat.socket;
    const websocket = yield select(wsState);

    if (!websocket) {
      return;
    }

    yield websocket.sendMessage(
      JSON.stringify({
        subject: 'fetch_count_no_reads',
        data: {},
      })
    );
  } catch (_) {
    // console.error(error);
  }
}

/**
 * Fetch data from chat
 */
function* fetchChatRequest() {
  try {
    const wsState = (state) => state.chat.socket;
    const websocket = yield select(wsState);

    yield websocket.sendMessage(
      JSON.stringify({
        subject: 'fetch_chats',
        data: {
          page: 0,
          filters: [],
          search: '',
          screen: 'currents',
        },
      })
    );
  } catch (_) {
    // console.error(error);
  }
}

/**
 * Fetch messages from chat
 */
function* fetchMessagesRequest(event) {
  try {
    const wsState = (state) => state.chat.socket;
    const websocket = yield select(wsState);

    let chat_id = event.chat;

    if (chat_id) {
      yield websocket.sendMessage(
        JSON.stringify({
          subject: 'fetch_messages',
          data: {
            contact_id: chat_id, //contact = chat
          },
        })
      );
    }
  } catch (e) {
    console.log('Occurs an error by action fetchMessagesRequest, Error => ', e);
  }
}

/**
 * Fetch more messages from chat (scroll up)
 * @param {*} chat
 */
function* fetchMoreMessagesRequest(event) {
  try {
    const wsState = (state) => state.chat.socket;
    const websocket = yield select(wsState);

    let chat_id = event.chat;
    let lastMessageId = event.lastMessageId;

    if (chat_id && lastMessageId) {
      yield websocket.sendMessage(
        JSON.stringify({
          subject: 'fetch_more_messages',
          data: {
            contact_id: chat_id, //contact = chat
            last_message_id: lastMessageId,
          },
        })
      );
    }
  } catch (e) {
    console.log(
      'Occurs an Erron in sagas file with fetchMoreMessagesRequest function, Error => ',
      e
    );
  }
}

/**
 * Fetch more chats
 * @param {*} chat
 */
function* fetchMoreChatsRequest(event) {
  try {
    const wsState = (state) => state.chat.socket;
    const websocket = yield select(wsState);

    let page = parseInt(event.page);
    if (page == -1) {
      page = 0;
    }
    let filters = event.filters;
    let search = event.search;
    let screen = event.screen;

    yield websocket.sendMessage(
      JSON.stringify({
        subject: 'fetch_more_chats',
        data: {
          page,
          filters,
          search,
          screen,
        },
      })
    );

    let payload = {
      time: new Date().getTime(),
      page: event.page,
    };

    if (filters.length) {
      payload.filters = event.filters;
    } else if (search.length) {
      payload.search = event.search;
      store.dispatch(startFetchingChatsLoading(payload));
    }
  } catch (e) {
    console.log(
      'Occurs an Erron in sagas file with fetchMoreChatsRequest function, Error => ',
      e
    );
  }
}

/**
 * Mark chat as read
 */
function* markAsRead(event) {
  try {
    if(!event.sendBack){
      return
    }
    const wsState = (state) => state.chat.socket;
    const websocket = yield select(wsState);

    let chat_id = event.chat_id;

    if (chat_id) {
      yield websocket.sendMessage(
        JSON.stringify({
          subject: 'mark_as_read',
          data: {
            contact_id: chat_id, //contact = chat
          },
        })
      );
    }
  } catch (e) {
    console.log(
      'Occurs an Error in Sagas file and markAsRead function, Erro => ',
      e
    );
  }
}

/**
 * Fetch users
 */
function* fetchUsersRequest() {
  try {
    const wsState = (state) => state.chat.socket;
    const websocket = yield select(wsState);

    yield websocket.sendMessage(
      JSON.stringify({
        subject: 'fetch_operators',
        data: {},
      })
    );
  } catch (e) {
    // console.error(error);
    console.log(
      'Occurs an Error in Sagas file with fetchUsersRequest function, Erro => ',
      e
    );
  }
}

/**
 * Finish chat
 */
function* finishChatRequest(event) {
  try {
    const wsState = (state) => state.chat.socket;
    const websocket = yield select(wsState);
    const chat_id = event.chat_id;
    const channel_id = event.channel_id;

    if (chat_id && channel_id) {
      yield websocket.sendMessage(
        JSON.stringify({
          subject: 'finish_chat',
          data: {
            contact_id: event.chat_id,
            channel_id: event.channel_id
          },
        })
      );
    }
  } catch (e) {
    console.log(
      'Occurs an Error in Sagas file with finishChatRequest function, Erro => ',
      e
    );
  }
}

/**
 * Open Transfer Chat Modal
 * */
function* openTransferChatModal() {
  try {
    const wsState = (state) => state.chat.socket;
    const websocket = yield select(wsState);

    yield websocket.sendMessage(
      JSON.stringify({
        subject: 'open_transfer',
        data: {},
      })
    );
  } catch (_) {
    // console.error(error);
  }
}

/**
 * Transfer Chat
 * */
function* transferChatRequest(event) {
  try {
    const wsState = (state) => state.chat.socket;
    const websocket = yield select(wsState);

    const operator_id = event.data.operator_id;
    const contact_id = event.data.contact_id;
    const wallet = event.data.wallet;
    const department_id = event.data.department_id;

    yield websocket.sendMessage(
      JSON.stringify({
        subject: 'redirect',
        data: {
          operator_id: operator_id,
          contact_id: contact_id,
          wallet: wallet,
          department_id: department_id,
        },
      })
    );
  } catch (_) {
    // console.error(error);
  }
}

/**
 * Pegar protocolo
 * */
function* getProtocol(event) {
  try {
    const wsState = (state) => state.chat.socket;
    const websocket = yield select(wsState);

    const chat_id = event.chat_id;

    yield websocket.sendMessage(
      JSON.stringify({
        subject: 'get_protocol',
        data: {
          chat_id: chat_id
        },
      })
    );
  } catch (_) {
    // console.error(error);
  }
}

/**
 * Send Text Message
 */
function* sendMessage(event) {
  try {
    const data = event.data;
    if (!data) return;

    const auxState = (state) => state.chat;
    const chatState = yield select(auxState);

    const websocket = chatState.socket;

    yield websocket.sendMessage(
      JSON.stringify({
        subject: 'send_message',
        data: {
          cuid: data.cuid,
          chat_id: data.chat_id,
          message_body: data.message_body,
          channel_id: data.channel_id,
          contact_id: data.contact_id,
          user_id: data.user_id,
          channel_external_id: data.external_id,
          reply_message_uid: data.reply_message_uid,
          type: data.type,
          quick_message_id: data.quick_message_id || null,
        },
      })
    );
  } catch (_) {
    // console.error(error);
  }
}

/**
 * Receive external id
 */
function* fetchContactExternal(event) {
  try {
    const wsState = (state) => state.chat.socket;
    const websocket = yield select(wsState);

    let chat_id = event.chat_id;

    yield websocket.sendMessage(
      JSON.stringify({
        subject: 'fetch_contact_external',
        data: {
          contact_id: chat_id,
        },
      })
    );
  } catch (_) {
    // console.error(e);
  }
}
// Busca os 20 primeiros contatos
function* fetchAllContacts() {
  try {
    const wsState = (state) => state.chat.socket;
    const userState = (state) => state.chat.config.user.id;

    const websocket = yield select(wsState);
    const user_id = yield select(userState);

    if (!websocket || !user_id) {
      return;
    }

    yield websocket.sendMessage(
      JSON.stringify({
        subject: 'fetch_contacts',
        data: {},
      })
    );
  } catch (_) {
    // console.error(error);
  }
}

/**
 * Busca mais contatos
 */
function* fetchMoreContactsRequest(event) {
  try {
    const wsState = (state) => state.chat.socket;
    const websocket = yield select(wsState);

    let page = event.page;
    let filters = event.filters;
    let search = event.search;

    yield websocket.sendMessage(
      JSON.stringify({
        subject: 'fetch_more_contacts',
        data: {
          page: page,
          filters: filters,
          search: search,
        },
      })
    );

    event.time = new Date().getTime();

    store.dispatch(startFetchingContactsLoading(event));
  } catch (_) {
    // console.error(error);
  }
}

function* createContactRequest(event) {
  let data = event.data;
  let addToPortfolio = event.bool;
  try {
    const wsState = (state) => state.chat.socket;
    const userState = (state) => state.chat.config.user.id;

    const websocket = yield select(wsState);
    const user_id = yield select(userState);

    if (!websocket || !user_id) {
      return;
    }

    yield websocket.sendMessage(
      JSON.stringify({
        subject: 'create_contact',
        data,
        addToPortfolio,
      })
    );
  } catch (_) {
    // console.error(error);
  }
}

function* updateContactRequest(event) {
  let data = event.data;
  let addToPortfolio = event.bool;

  try {
    const wsState = (state) => state.chat.socket;
    const userState = (state) => state.chat.config.user.id;

    const websocket = yield select(wsState);
    const user_id = yield select(userState);

    if (!websocket || !user_id) {
      return;
    }
    yield websocket.sendMessage(
      JSON.stringify({
        subject: 'update_contact',
        data,
        addToPortfolio,
      })
    );
  } catch (_) {
    // console.error(error);
  }
}

function* deleteContactExternalRequest(event) {
  let data = event.data;

  try {
    const wsState = (state) => state.chat.socket;
    const userState = (state) => state.chat.config.user.id;

    const websocket = yield select(wsState);
    const user_id = yield select(userState);

    if (!websocket || !user_id) {
      return;
    }
    yield websocket.sendMessage(
      JSON.stringify({
        subject: 'delete_contact_external',
        data,
      })
    );
  } catch (_) {
    // console.error(error);
  }
}

/**
 * Buscando apenas um chat
 */
function* fetchUniqueChatRequest(event) {
  try {
    const wsState = (state) => state.chat.socket;
    const websocket = yield select(wsState);

    yield websocket.sendMessage(
      JSON.stringify({
        subject: 'get_chat',
        data: {
          contact_id: event.id,
        },
      })
    );
  } catch (_) {
    // console.error(error);
  }
}
/**
 * Buscando lista de chats
 */
function* fetchListChatsRequest(event) {
  try {
    const wsState = (state) => state.chat.socket;
    const websocket = yield select(wsState);
    const pendencesState = (state) => state.chat.pendences;
    const pendences = yield select(pendencesState);

    yield websocket.sendMessage(
      JSON.stringify({
        subject: 'fetch_pendences',
        data: pendences,
      })
    );
  } catch (_) {
    // console.error(error);
    store.dispatch(fetchMoreChatsFailure(event.payload));
  }
}
/**
 * submit filter
 * @param {*} event
 */
function* submitFilter(event) {
  try {
    let state = yield select((state) => state.chat);
    let chats = Object.assign([], state.chats);
    let activeFilters = Object.assign({}, event.payload);
    let screen = event.screen;

    const page = state?.currentPage?.customFilter.page;

    // Aplica o filtro aos chats
    chats = applyChatFilters(chats, activeFilters);

    //atualiza o filtro no state
    store.dispatch(updateFilters(event.payload));

    // Utilizar em breve para melhorar os filtros aninhados CustomFilter
    // if (chats.length && chats.length > 0 && screen === 'all') {
    //   store.dispatch(updateCurrentPageCustomFilter((Math.floor(chats.length / LIMIT_CHATS_PER_PAGE))))
    // }

    const wsState = (state) => state.chat.socket;
    const websocket = yield select(wsState);
    yield websocket.sendMessage(
      JSON.stringify({
        subject: 'fetch_more_chats',
        data: {
          filters: activeFilters,
          page,
          screen,
        },
      })
    );
  } catch (_) {
    // console.error(e);
  }
}

/**
 * submit search
 * @param {*} event
 */
function* search(event) {
  try {
    let state = yield select((state) => state.chat);
    let chats = Object.assign([], state.chats);
    let contacts = Object.assign([], state.contacts);
    // Normalize e Replace usados para deixar o mais padrão possível, sem acentos ou caracteres especiais
    let searchTerm = event.payload
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    let whoSendAction = event.whoSendAction;

    // Se a ação foi disparada pelo Chat então executará esse bloco
    if (whoSendAction === 'chat') {
      // Verificar se o existe o chatsSearched e ele está preenchido
      // Necessário deixar limpo
      if (state.search.chatsSearched) {
        store.dispatch(clearResultsInSearchChats());
      }

      const wsState = (state) => state.chat.socket;
      const websocket = yield select(wsState);

      const page = state.chat?.currentPage?.search?.page;
      const screen = event.screen;

      yield websocket.sendMessage(
        JSON.stringify({
          subject: 'fetch_more_chats',
          data: {
            search: searchTerm,
            page,
            screen,
          },
        })
      );
      // }

      // Serve para mostrar ao usuário o chat pesquisado, mesmo que troque de aba
      store.dispatch(updateChatSearch(searchTerm));
    }

    // Se a ação foi disparada na aba CONTATOS então executará este bloco
    else if (whoSendAction === 'contact') {
      const wsState = (state) => state.chat.socket;
      const websocket = yield select(wsState);
      const page = Math.floor(contacts.length / LIMIT_CHATS_PER_PAGE);
      yield websocket.sendMessage(
        JSON.stringify({
          subject: 'search_contact',
          data: {
            search: searchTerm,
            page,
          },
        })
      );
      // Serve para mostrar ao usuário o contato pesquisado, mesmo que troque de aba
      store.dispatch(updateContactSearch(seasearchTermch));
    }
  } catch (_) {
    // console.error(e);
  }
}

/**
 * Insert new tag to contact
 */
function* insertTagToContactSubmit(event) {
  try {
    const wsState = (state) => state.chat.socket;
    const websocket = yield select(wsState);

    if (event.payload) {
      let contact_id = event.payload.chat_id;
      let tags = event.payload.tags;

      yield websocket.sendMessage(
        JSON.stringify({
          subject: 'insert_tag_to_contact',
          data: {
            contact_id: contact_id,
            tags: tags,
          },
        })
      );

      store.dispatch(insertTagToContactRequest(contact_id));
    }
  } catch (_) {
    // console.error(e);
  }
}

/**
 * Remove tag from contact
 */
function* removeTagFromContactRequest(event) {
  try {
    const wsState = (state) => state.chat.socket;
    const websocket = yield select(wsState);

    if (event.payload) {
      let tag_id = event.payload.tag_id;
      let contact_id = event.payload.chat_id;

      if (tag_id > 0) {
        yield websocket.sendMessage(
          JSON.stringify({
            subject: 'remove_tag_from_contact',
            data: {
              tag_id: tag_id,
              contact_id: contact_id,
            },
          })
        );

        store.dispatch(removeTagFromContactSuccess(event.payload));
      }
    }
  } catch (_) {
    // console.error(e);
  }
}

/**
 * buscar mensagens rapidas
 */
function* fetchQuickMessages(event) {
  try {
    const wsState = (state) => state.chat.socket;

    let websocket = yield select(wsState);

    if(!websocket)
    {
      //Aguardar websocket conectar
      yield new Promise((resolve) => setTimeout(resolve, 3000));
      websocket = yield select(wsState);
    }

    yield websocket.sendMessage(
      JSON.stringify({
        subject: 'fetch_quick_messages',
        data: {},
      })
    );

  } catch (_) {
    // console.error(e);
  }
}
/**
 * criar mensagem rapida
 */
function* createQuickMessage(event) {
  try {
    const wsState = (state) => state.chat.socket;
    const websocket = yield select(wsState);
    yield websocket.sendMessage(
      JSON.stringify({
        subject: 'create_quick_message',
        data: event.payload,
      })
    );
  } catch (_) {
    // console.error(e);
  }
}
function* updateQuickMessage(event) {
  try {
    const wsState = (state) => state.chat.socket;
    const websocket = yield select(wsState);
    yield websocket.sendMessage(
      JSON.stringify({
        subject: 'update_quick_message',
        data: event.payload,
      })
    );
  } catch (_) {
    // console.error(e);
  }
}

function* deleteQuickMessage(event) {
  try {
    const wsState = (state) => state.chat.socket;
    const websocket = yield select(wsState);
    yield websocket.sendMessage(
      JSON.stringify({
        subject: 'delete_quick_message',
        data: event.payload,
      })
    );
  } catch (_) {
    // console.error(e);
  }
}

/**
 * Start send message, Get S3 Signed Url for medias
 */
function* sendFilesMessage(event) {
  try {
    const wsState = (state) => state.chat.socket;
    const websocket = yield select(wsState);

    if (!websocket) {
      return;
    }

    yield websocket.sendMessage(
      JSON.stringify({
        subject: 'get_s3_signed_url_file_message',
        data: event.payload,
      })
    );
  } catch (_) {
    // console.error(error);
  }
}

function* uploadFileMessage(event) {
  const payload = event.payload;

  if (!payload || !payload.chat_id || !payload.file_id) return;

  try {
    if (
      !payload.destination ||
      !payload.destination.access_url ||
      !payload.destination.signed_url_request
    )
      throw new Error('invalid data');

    const stChatState = (state) => state.chat.chats;
    const chats = yield select(stChatState);

    let currentChat = chats.find((c) => c.id === payload.chat_id);
    if (!currentChat) return;

    let currentFile = currentChat.uploadingFiles.find(
      (f) => f.id === payload.file_id
    );
    if (!currentFile) return;

    let currentMessage = currentChat.messages.find(
      (m) => m.file_uploading_id === payload.file_id
    );
    if (!currentMessage) return;

    let uploadFile = async function (upload_data) {
      return await axios.put(
        upload_data.payload.destination.signed_url_request,
        upload_data.file,
        {
          headers: {
            'Content-Type': upload_data.file.type,
          },
        }
      );
    };

    yield call(uploadFile, { payload, file: currentFile.file });

    let update_data = {
      chat_id: payload.chat_id,
      files: [{ id: payload.file_id }],
      uploaded: true,
      url: payload.destination.access_url,
    };

    store.dispatch(updateStateUploadFilesMessage(update_data));

    store.dispatch(
      updateMessageUrl({
        chat_id: payload.chat_id,
        message_cuid: currentMessage.cuid,
        message_url: payload.destination.access_url,
      })
    );

    let data_send = {
      chat_id: currentChat?.id,
      channel_id: findChannelId(currentChat),
      user_id: currentChat.config?.user?.id,
      contact_id: currentChat?.contact?.id,
      external_id: findSelectedExternalId(currentChat),
      message_body: {
        url: payload.destination.access_url,
        name: currentFile.name,
        size: currentFile.size,
        caption: currentMessage.caption,
        mimetype: currentFile.file.type,
      },
      cuid: currentMessage.cuid,
      type: currentMessage.type,
      reply_message_uid:
        currentMessage.reply_message && currentMessage.reply_message.uid
          ? currentMessage.reply_message.uid
          : null,
    };

    yield* sendMessage({ data: data_send });

    // Responsável por fechar o dropzone
    store.dispatch(clearUploadsAfterSend(data_send.chat_id));
  } catch (_) {
    let update_data = {
      chat_id: payload.chat_id,
      files: [{ id: payload.file_id }],
      error: { code: 'ERROR_UPLOAD_FILE', message: 'Error on upload to aws' },
      uploaded: false,
    };
    store.dispatch(updateStateUploadFilesMessage(update_data));
  }
}

function* mergeContactSearch(event) {
  try {
    const wsState = (state) => state.chat.socket;
    const websocket = yield select(wsState);
    yield websocket.sendMessage(
      JSON.stringify({
        subject: 'merge_contact_search',
        data: event.payload,
      })
    );
  } catch (_) {
    store.dispatch(mergeContactSearchFailure(event.payload));
    // console.error(e);
  }
}
function* unmergeContacts(event) {
  try {
    const wsState = (state) => state.chat.socket;
    const websocket = yield select(wsState);
    yield websocket.sendMessage(
      JSON.stringify({
        subject: 'unmerge_contacts',
        data: event.payload,
      })
    );
  } catch (_) {
    store.dispatch(unmergeContactsFailure(event.payload));
    // console.error(e);
  }
}
function* mergeContacts(event) {
  try {
    const wsState = (state) => state.chat.socket;
    const websocket = yield select(wsState);
    yield websocket.sendMessage(
      JSON.stringify({
        subject: 'merge_contacts',
        data: event.payload,
      })
    );
  } catch (_) {
    store.dispatch(mergeContactsFailure(event.payload));
    // console.error(e);
  }
}
/*
function* startVisitorsService(event) {
  try {
    const wsState = (state) => state.chat.socket;
    const websocket = yield select(wsState);
    yield websocket.sendMessage(
      JSON.stringify({
        subject: 'start_visitors_service',
        data: {},
      })
    );
  } catch (_) {
    store.dispatch(startVisitorsServiceFailure());
    // console.error(e);
  }
}
*/
/*
function* storeFromVisitor(event) {
  // setTimeout(() => {
  //   store.dispatch(storeFromVisitorFailure(event.payload));
  // }, 30000);
  // return null;
  try {
    const wsState = (state) => state.chat.socket;
    const websocket = yield select(wsState);
    yield websocket.sendMessage(
      JSON.stringify({
        subject: 'store_from_visitor',
        data: event.payload,
      })
    );
  } catch (_) {
    store.dispatch(storeFromVisitorFailure(event.payload));
    // console.error(e);
  }
}
*/

function* paymentChatRequest(event) {
  try {
    const wsState = (state) => state.chat.socket;
    const websocket = yield select(wsState);
    yield websocket.sendMessage(
      JSON.stringify({
        subject: 'payment_chat_request',
        data: event.payload,
      })
    );
  } catch (_) {
    store.dispatch(paymentChatRequestFailure());
    // console.error(e);
  }
}

function* catalogChatRequest(event) {
  try {
    let resultProducts = yield call(ProductService.getProducts({ status: 1 }));
    let resultCategories = yield call(ProductService.getAllCategories);

    let data = {
      products: resultProducts.data.data,
      categories: resultCategories.data,
      currentPagination: resultProducts.data.current_page,
      lastPage: resultProducts.data.last_page,
    };

    store.dispatch(fetchCatalogSuccess(data));
  } catch (_) {
    store.dispatch(fetchCatalogFailure());
    // console.error(e);
  }
}

function* fetchMoreProducts(event) {
  try {
    let resultProducts = yield call(ProductService.getProducts, {
      page: event.payload.nextPage,
      category_id: event.payload.category,
      status: event.payload.status,
    });
    let data = {
      products: resultProducts.data.data,
      currentPagination: resultProducts.data.current_page,
      lastPage: resultProducts.data.last_page,
    };

    store.dispatch(fetchMoreProductSuccess(data));
  } catch (_) {
    store.dispatch(fetchCatalogFailure());
  }
}

function* catalogCategoriesChatRequest(event) {
  // setTimeout(() => {
  //   store.dispatch(fetchCatalogCategoriesSuccess());
  // }, 3000);
  // return null;
  try {
    //   const wsState = (state) => state.chat.socket;
    //   const websocket = yield select(wsState);
    //   yield websocket.sendMessage(
    //     JSON.stringify({
    //       subject: 'catalog_categories_chat_request',
    //       data: event.payload,
    //     })
    //   );
    let resultCategories = yield call(ProductService.getAllCategories);

    let data = {
      categories: resultCategories.data,
    };

    yield put(fetchCatalogSuccess(data));
  } catch (_) {
    store.dispatch(fetchCatalogFailure());
    // console.error(e);
  }
}

function* fetchShoppingCartDetail(event) {
  try {
    let cartDetail = yield call(OrderService.getOrderDetail, event.payload);

    let cart = {
      id: cartDetail.data.id,
      type: 'order',
      products: cartDetail.data.items,
      address: cartDetail.data.address,
      shipping_cost: cartDetail.data.shipping_cost,
      checkout_path: cartDetail.data.checkout_url,
      integration_id: cartDetail.data.integration_id,
    };

    yield put(changeChatCart(cartDetail.data.contact_id, cart));

    store.dispatch(showCartButton());
  } catch (e) {
    store.dispatch(fetchShopppingCartDetailFailure(e));
  }
}

function* createShoppingCartRequest(event) {
  try {
    event.payload.type = 'order';

    let response = yield call(OrderService.createOrder, event.payload);

    let cart = {
      id: response.data.order.id,
      type: event.payload.type,
      products: event.payload.products,
      address: event.payload.address,
      checkout_path: response.data.order.checkout_url,
    };

    yield put(changeChatCart(event.payload.contact_id, cart));

    store.dispatch(showCartButton());
    toast.success('Carrinho criado!');
  } catch (_) {
    // console.error(e);
    store.dispatch(createShopppingCartFailure());
    toast.error('Não foi possível criar carrinho!');
  }
}

function* updateShoppingCartRequest(event) {
  try {
    yield call(OrderService.updateOrder, event.order_id, event.order_data);
  } catch (e) {
    store.dispatch(updateShopppingCartFailure(event.chat_id, event.order_id));
  }
}

function* sendOrderRequest(event) {
  try {
    yield call(
      OrderService.markOrderSended,
      event.order_id,
      event.integration_id ?? event.integration_id
    );

    if (event.message) {
      store.dispatch(storageMessage(event.message.data_to_storage));

      store.dispatch(sendMessageToServer(event.message.data_to_send));
    }

    if (event.order_type == 'order') {
      store.dispatch(showCartButton(false));
      store.dispatch(clearShoppingCart(event.chat_id, event.order_id));
    } else if (event.order_type == 'debt') {
      store.dispatch(closePaymentChatModal());
    }
  } catch (_) {}
}

function* fetchOpportunitiesAndTicketsRequest(event) {
  try {
    let getcards = async function (contact) {

      try {
        const response = await getOpportunitiesAndTickets(contact);
        if (response && response.data) {
          return response.data
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    }
    const response = yield call(getcards, event.payload);

    if (!response) {
      store.dispatch(fetchOpportunitiesAndTicketsFailure(event.payload));
      return;
    }
    store.dispatch(
      fetchOpportunitiesAndTicketsSuccess({ ...event.payload, cards: response })
    );

  } catch (_) {
    store.dispatch(fetchOpportunitiesAndTicketsFailure(event.payload));
  }
}

export default all([
  takeLatest(actionNames.MERGE_CONTACT_SEARCH, mergeContactSearch),
  takeLatest(actionNames.MERGE_CONTACTS, mergeContacts),
  takeLatest(actionNames.UNMERGE_CONTACTS, unmergeContacts),
  // 1º parametro é qual ação queremos ouvir
  // 2º parametro é qual action queremos disparar
  takeLatest(actionNames.FETCH_CHAT_CONFIG_REQUEST, fetchChatConfig),
  takeLatest(actionNames.OPEN_SOCKET_REQUEST, openSocketRequest),
  takeLatest(actionNames.FETCH_CHAT_REQUEST, fetchChatRequest),
  takeLatest(actionNames.FETCH_COUNT_NO_READS, fetchCountNoReads),
  takeLatest(actionNames.FETCH_MESSAGES_REQUEST, fetchMessagesRequest),
  takeLatest(actionNames.FETCH_MESSAGES_REQUEST_ONE_CHAT, fetchMessagesRequest),
  takeLatest(actionNames.FETCH_MORE_MESSAGES_REQUEST, fetchMoreMessagesRequest),

  takeLatest(actionNames.FETCH_MORE_CHATS_REQUEST, fetchMoreChatsRequest),
  takeLatest(actionNames.MARK_READ, markAsRead),
  takeLatest(actionNames.FINISH_CHAT_REQUEST, finishChatRequest),
  takeLatest(actionNames.OPEN_TRANSFER_CHAT_MODAL, openTransferChatModal),
  takeLatest(actionNames.FETCH_USERS_REQUEST, fetchUsersRequest),
  takeLatest(actionNames.TRANSFER_CHAT_REQUEST, transferChatRequest),
  takeEvery(actionNames.SEND_MESSAGE, sendMessage),
  takeLatest(actionNames.FETCH_CONTACT_EXTERNAL, fetchContactExternal),
  takeLatest(actionNames.FETCH_UNIQUE_CHAT_REQUEST, fetchUniqueChatRequest),
  takeLatest(actionNames.SUBMIT_FILTER, submitFilter),
  takeLatest(
    actionNames.INSERT_TAG_TO_CONTACT_SUBMIT,
    insertTagToContactSubmit
  ),
  takeLatest(actionNames.FETCH_CONTACTS_REQUEST, fetchAllContacts),
  takeLatest(actionNames.FETCH_MORE_CONTACTS_REQUEST, fetchMoreContactsRequest),
  takeLatest(actionNames.CREATE_CONTACT_REQUEST, createContactRequest),
  takeLatest(actionNames.UPDATE_CONTACT_REQUEST, updateContactRequest),
  takeLatest(
    actionNames.DELETE_CONTACT_EXTERNAL_REQUEST,
    deleteContactExternalRequest
  ),
  takeLatest(
    actionNames.REMOVE_TAG_FROM_CONTACT_REQUEST,
    removeTagFromContactRequest
  ),
  takeLatest(actionNames.FETCH_QUICK_MESSAGES, fetchQuickMessages),
  takeLatest(actionNames.CREATE_QUICK_MESSAGE, createQuickMessage),
  takeLatest(actionNames.UPDATE_QUICK_MESSAGE, updateQuickMessage),
  takeLatest(actionNames.DELETE_QUICK_MESSAGE, deleteQuickMessage),
  takeLatest(actionNames.SEARCH, search),
  takeEvery(actionNames.SEND_FILES_MESSAGE, sendFilesMessage),
  takeEvery(actionNames.UPLOAD_FILE_MESSAGE, uploadFileMessage),

  takeLatest(actionNames.FETCH_PENDENCES_REQUEST, fetchListChatsRequest),

  //Pagamento
  takeLatest(actionNames.PAYMENT_CHAT_REQUEST, paymentChatRequest),
  takeLatest(actionNames.FETCH_CATALOG, catalogChatRequest),
  takeLatest(actionNames.FETCH_MORE_PRODUCT, fetchMoreProducts),
  takeLatest(
    actionNames.FETCH_CATALOG_CATEGORIES,
    catalogCategoriesChatRequest
  ),

  takeLatest(
    actionNames.FETCH_SHOPPING_CART_DETAIL_REQUEST,
    fetchShoppingCartDetail
  ),
  takeLatest(
    actionNames.CREATE_SHOPPING_CART_REQUEST,
    createShoppingCartRequest
  ),
  takeLatest(
    actionNames.UPDATE_SHOPPING_CART_REQUEST,
    updateShoppingCartRequest
  ),
  takeLatest(actionNames.CHAT_SEND_ORDER, sendOrderRequest),

  takeLatest(
    actionNames.GET_PROTOCOL,
    getProtocol
  ),

  takeLatest(
    actionNames.FETCH_OPPORTUNITIES_AND_TICKETS_REQUEST,fetchOpportunitiesAndTicketsRequest
    )

  // visitors
  // takeEvery(actionNamesVisitors.START_VISITORS_SERVICE, startVisitorsService),
  // takeLatest(actionNamesVisitors.STORE_FROM_VISITOR, storeFromVisitor),
]);
