import { regularChannelHasCredits } from './regularChannels';
import { wabaChannelHasCredits } from './wabaChannels';

export function userCanSendMessage(
  channelId: number | undefined,
  data: any,
  flags?: any,
  isWhitelabel?: boolean,
  customerId?: number
) {
  if (channelId === undefined || data === undefined) {
    return false
  };

  if (!channelExists(channelId, data)) return true;

  const isRegular = isRegularChannel(channelId, data);

  const channelHasCredits = isRegular
    ? regularChannelHasCredits(channelId, data)
    : wabaChannelHasCredits(channelId, data);

  const hasDetached = hasDetachedCredits(data);
  const hasShould = hasShouldSpend(data);

  if (analyzeCredits(channelHasCredits, hasDetached, hasShould)) {
    return true;
  }

  if (isWhitelabel) {
    return false;
  }

  const customers = JSON.parse(flags.credit_after_zero_customers?.value);
  if (customers.indexOf(customerId) != -1 || customers.length === 0) {
    return true;
  }

  return false;
}

export function channelExists(channelId: number, data: any) {
  return channelId.toString() in data;
}

export function isRegularChannel(channelId: number, data: any) {
  return !data[channelId.toString()].isWaba;
}

function hasDetachedCredits(data: any) {
  const { detachedCredits } = data;
  if (detachedCredits == undefined) return false;
  return detachedCredits.used < detachedCredits.total;
}

function hasShouldSpend(data: any) {
  const { contact } = data;
  if (contact == undefined) return false;

  /**
   * SHOULD_SPEND logic
   * if should_spend is TRUE means in the last 24h the credit not are used, so block chat
   * if should_spend is FALSE means credits was used, can enable chat
   */
  return !contact.should_spend;
}

function analyzeCredits(
  channelHasCredits: boolean,
  hasDetached: boolean,
  hasShould: boolean
) {
  if (channelHasCredits) return true;

  if (hasDetached) return true;

  if (hasShould) return true;

  return false;
}
