import { getEmojiDataFromNative } from 'emoji-mart';
import data from 'emoji-mart/data/all.json';

const emailRegex =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi;
const urlRegex =
  /\s((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;

function isShowPlainTextBalloon(text) {
  const iframeRegex = /(<iframe.*>.*< *\/ *iframe *>)/g;

  return JSON.stringify(text).match(iframeRegex) ? true : false;
}

export default function formatText(text, pattern = 'whatsapp') {
  if (text === null || text === undefined) return '';

  if (isShowPlainTextBalloon(text)) {
    return text;
  }

  // Esse caso é utilizado pelo LastMessage e aparece na pré-visualização da última mensagem do chat
  // Fazemos a remoção dos caracteres de formatação e deixamos a mensagem limpa, sem nenhuma HTML,
  // pois é assim que o WhatsApp se comporta
  if (pattern === 'title') {
    return removeFormatting(text);
  }

  text = ' ' + text;

  const URLsToReplace = [];
  const emailsToReplace = [];

  /*
  Os dois loops for (... of ...) a seguir foram implementados para impedir que
  os caracteres dentro de URLs fossem interpretados como caracteres de formata-
  ção do WhatsApp. Assim, o primeiro loop encontra todas as URLs contidas dentro
  do texto e retira elas do mesmo. A formatação segue como padrão e em seguida
  as URLs são adicionadas de volta aos seus lugares
  */

  const urlSecretString = '@@@@###'; // Usada como placeholder para as URLs durante a fase de formatação
  const urlMatches = text.matchAll(urlRegex);
  let urlFoundMatches = false;
  for (const match of urlMatches) {
    urlFoundMatches = true;
    text =
      text.slice(0, text.indexOf(match[0]) + 1) +
      urlSecretString +
      text.slice(text.indexOf(match[0]) + match[0].length);
    URLsToReplace.push(match[0].slice(1));
  }

  const emailSecretString = '&&&%%%'; // Usada como placeholder para as URLs durante a fase de formatação
  const emailMatches = text.matchAll(emailRegex);
  let emailFoundMatches = false;
  for (const match of emailMatches) {
    emailFoundMatches = true;
    text =
      text.slice(0, text.indexOf(match[0]) + 1) +
      emailSecretString +
      text.slice(text.indexOf(match[0]) + match[0].length);
    emailsToReplace.push(match[0].slice(1));
  }

  if (pattern === 'whatsapp' || pattern === 'title') {
    text = formatWpp(text);
  }

  // Coloca os emails de volta no lugar
  if (emailFoundMatches) {
    let emailIndex = text.indexOf(emailSecretString);
    let count = 0;
    while (emailIndex >= 0) {
      text =
        text.slice(0, emailIndex) +
        emailsToReplace[count] +
        text.slice(emailIndex + emailSecretString.length);
      emailIndex = text.indexOf(emailSecretString);
      count++;
    }
  }

  // Coloca as URLs de volta no lugar
  if (urlFoundMatches) {
    let URLIndex = text.indexOf(urlSecretString);
    let count = 0;
    while (URLIndex >= 0) {
      text =
        text.slice(0, URLIndex) +
        URLsToReplace[count] +
        text.slice(URLIndex + urlSecretString.length);
      URLIndex = text.indexOf(urlSecretString);
      count++;
    }
  }

  text = linkify(text);

  text = emojify(text);

  return text;
}

function linkify(text) {
  text = text.replace(emailRegex, function (url) {
    let emailWithProtocol = /(mailto)/.test(url) ? url : 'mailto:' + url;
    return (
      '<a href="' +
      emailWithProtocol +
      '" target="_blank" rel="noopener noreferrer">' +
      url +
      '</a>'
    );
  });

  let urlRegex = /(https?:\/\/[^\s]+)/g;
  return text
    .replace(urlRegex, function (url) {
      url = url.trim();
      let urlWithProtocol = /(http|https|Http|Https|rtsp|Rtsp)/.test(url)
        ? url
        : 'http://' + url;

      return (
        '<a style="color: var(--primary);" href="' +
        urlWithProtocol +
        '" target="_blank" rel="noopener noreferrer">' +
        url +
        '</a>'
      );
    })
    .trim();
}

function emojify(text) {
  const emojiRegex =
    /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;

  return text.replace(emojiRegex, function (emoji) {
    const emojiData = getEmojiDataFromNative(emoji, 'apple', data);
    if (emojiData) {
      return `<span
      className="emoji-message"
      style="width: 20px;
      height: 20px;
      display: inline-block;
      background-image: url('https://unpkg.com/emoji-datasource-apple@5.0.1/img/apple/sheets-256/64.png');
      background-size: 5700% 5700%;
      background-position: ${data.emojis[emojiData.id].sheet_x * 1.78751}% ${
        data.emojis[emojiData.id].sheet_y * 1.78751
      }%;"
      title="${emoji}"
      alt="${emoji}" draggable="false" data-plain-text="${emoji}"></span>`;
    } else {
      return `<span style="width: 20px; height: 20px;" title="${emoji}">${emoji}</span>`;
    }
  });
}

function formatWpp(text) {
  switch (typeof text) {
    case 'string':
      break;
    case 'number':
      text = text.toString();
      break;
    default:
      console.error('Invalid type of text');
      return text;
  }

  return text
    .replace(/\</g, '&lt')
    .replace(/\>/g, '&gt')
    .replace(
      /(\_)(?=\S)([^\r]*?\S[*_]*)\1(?![a-zA-Z0-9])/g, // Itálico
      '<i style="font-style:italic">$2</i>'
    )
    .replace(
      /(\*)(?=\S)([^\r]*?\S[*_]*)\1(?![a-zA-Z0-9])/g, // Negrito
      '<b>$2</b>'
    )
    .replace(
      /(\~)(?=\S)([^\r]*?\S[*_]*)\1(?![a-zA-Z0-9])/g, // Riscado
      '<strike>$2</strike>'
    )
    .replace(
      /(\`{3})(?=\S)([^\r]*?\S[*_]*)\1(?![a-zA-Z0-9])/g, // Monospace/código
      '<code>$2</code>'
    );
}

function removeFormatting(text) {
  switch (typeof text) {
    case 'string':
      break;
    case 'number':
      text = text.toString();
      break;
    default:
      console.error('Invalid type of text');
      return text;
  }

  return text
    .replace(
      /(\_)(?=\S)([^\r]*?\S[*_]*)\1(?![a-zA-Z0-9])/g, // Itálico
      '$2'
    )
    .replace(
      /(\*)(?=\S)([^\r]*?\S[*_]*)\1(?![a-zA-Z0-9])/g, // Negrito
      '$2'
    )
    .replace(
      /(\~)(?=\S)([^\r]*?\S[*_]*)\1(?![a-zA-Z0-9])/g, // Riscado
      '$2'
    )
    .replace(
      /(\`{3})(?=\S)([^\r]*?\S[*_]*)\1(?![a-zA-Z0-9])/g, // Monospace/código
      '$2'
    );
}
