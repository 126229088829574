import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import packageJson from '../../../../../../package.json';
import { logout as logoutAction } from '../../../../../store/modules/auth/actions';
import poliConfig from '../../../../../utils/poliConfig';

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Icon,
} from '@polichat/flamboyant';
import { useLocation } from 'react-router-dom';
import DarkMode from '../../header/actions/DarkMode';
import Organizations from '../../header/actions/Organizations';

import SidebarItem from './SidebarItem';
import SidebarMainIcon from './SidebarLogo';

import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../../store/modules/chat/actions';

import routes from '../../../../../routes/Routes';
import Avatar from '../../../profile/Avatar';
import { ToggleNotifications } from '../../header/actions/ToggleNotifications';

function SidebarWhitelabel({ config, props, userConfig, current_customer_id }) {
  let operator;
  let avatarOperator;
  let nameOperator;
  let idOperator;
  let currentCustomer;
  const multiCustomers = userConfig?.customers.length > 1 ? true : false;
  const superRole = userConfig?.superRole;
  const dispatch = useDispatch();

  const wlConfig = useSelector((state) => state?.whitelabel?.config);
  const isAuvo =
    useSelector((state) => state?.whitelabel?.config?.app_title) === 'Auvo';

  const [showRoutes, setShowRoutes] = useState(routes);

  const enableAds =
    poliConfig?.ads_enable ||
    poliConfig?.ads_enable_custumer?.includes(Number(current_customer_id));

  useEffect(() => {
    if (
      routes &&
      routes.length > 0 &&
      wlConfig &&
      wlConfig.is_white_label &&
      wlConfig.buttons
    ) {
      var buttons = wlConfig.buttons;
      var _routes = [];

      routes.map((route) => {
        var canShow = buttons.find((e) => e.id === route.tag);
        if (canShow) {
          _routes.push(route);
        }
      });

      setShowRoutes(_routes);
    }
  }, [wlConfig, routes]);

  // Validações para utilizar o nome, id e imagem do operador
  if (!userConfig || userConfig === undefined) {
    avatarOperator = '';
    nameOperator = '';
    idOperator = '';
  } else {
    nameOperator = userConfig.name;
    avatarOperator = userConfig.picture;

    idOperator = userConfig.id;
    currentCustomer = current_customer_id;
  }

  let location = useLocation();
  function isActive(path, subitens) {
    if (path === location.pathname) return true;
    if (!subitens) return false;
    return RegExp(path + '/.+', 'gi').test(location.pathname);
  }

  function isPwa() {
    return localStorage.getItem('pwa');
  }

  const [isWl, setIsWl] = useState();
  const wlState = useSelector((state) => state?.whitelabel);
  const [operatorMenu, setOperatorMenu] = useState(false);
  const [supportMenu, setSupportMenu] = useState(false);
  const [loginUrlReseller, setLoginUrlReseller] = useState();

  useEffect(() => {
    const _config = wlState?.config;
    const _isWl = _config?.is_white_label;
    setLoginUrlReseller(_config?.laravel_url);
    setIsWl(_isWl);
  }, [wlState]);

  const toggle = () => setOperatorMenu((prevState) => !prevState);

  //Verifica se tem a classe MenuRetratil na cache do Browser
  const toggleMenu = () => {
    if (localStorage.getItem('@polichat/menuRetratil') === 'true') {
      document.getElementById('root').classList.add('menuRetratil');
      return true;
    }
    return false;
  };

  const [menuRetratil, handleMenuRetratil] = useState(toggleMenu());

  // Função que aciona o Menu Retrátil
  function handleMenu() {
    const rootElement = document.getElementById('root');
    if (rootElement.classList.contains('menuRetratil')) {
      // Aumentou o menu
      handleMenuRetratil(false);
      rootElement.classList.remove('menuRetratil');
      localStorage.removeItem('@polichat/menuRetratil');
    } else {
      // Diminuiu o menu
      handleMenuRetratil(true);
      rootElement.classList.add('menuRetratil');
      localStorage.setItem('@polichat/menuRetratil', true);
    }
  }

  async function logout() {
    console.log('logout from sidebar');
    dispatch(logoutAction());
    window.location.href = '/login';
  }

  function myAccount() {
    if (isWl && loginUrlReseller?.toString()?.length > 2) {
      window.location.href = `${loginUrlReseller}/users/${currentCustomer}/${idOperator}/edit`;
    } else {
      window.location.href = `${poliConfig.poliAppLaravelUrl}/users/${currentCustomer}/${idOperator}/edit`;
    }
  }

  return (
    <>
      <SidebarMainIcon />
      <ul className="sidebarMenu">
        {showRoutes.map((route, index) => {
          let active = isActive(route.path, route.subitens);
          let show = true;
          let path = route.path;

          // is PWA
          if (isPwa()) {
            show = route.showPwa;
            if (route.path === '/') {
              path = '/chat';
            }
          }

          if (!enableAds && path === '/ads') {
            return <></>;
          }

          if (route.sidebarName && show) {
            return (route.needAdmin && superRole === 'adm') ||
              !route.needAdmin ? (
              <li key={index}>
                <SidebarItem
                  key={index}
                  name={route.sidebarName}
                  url={path}
                  icon={route.icon}
                  active={active}
                  title={route.title}
                  openNewPage={
                    route?.openNewPage || route?.openNewPage === false
                      ? route.openNewPage
                      : null
                  }
                />
              </li>
            ) : null;
          }

          return false;
        })}
      </ul>

      {!isAuvo && (
        <>
          <div className="supportMenu sidebarMenuItem">
            <Dropdown
              direction="right"
              isOpen={supportMenu}
              toggle={() => setSupportMenu((prevState) => !prevState)}
            >
              <DropdownToggle color={'link'} className="supportMenuButton">
                <Icon icon="poli-icon pi-question-line" />
                <span>Suporte</span>
              </DropdownToggle>
              <DropdownMenu className="menuDropdownShow">
                <DropdownItem
                  className="itemDropdownMenu"
                  onClick={() =>
                    window.open('https://forms.gle/f8FGaGFENdgUbo9r9')
                  }
                >
                  <Icon
                    icon={'poli-icon pi-alerts-line'}
                    color={'var(--green-polichat)'}
                    size={25}
                    position={'relative'}
                    left={'2px'}
                  />
                  <p className="alternative">Sugestões</p>
                  <Icon
                    icon={'poli-icon pi-simple-arrow-right-line'}
                    color={'var(--gray-75)'}
                    position={'relative'}
                    size={15}
                  />
                </DropdownItem>

                <DropdownItem
                  className="itemDropdownMenu"
                  onClick={() =>
                    window.open('https://poli.digital/central-de-ajuda/')
                  }
                >
                  <Icon
                    icon={'poli-icon pi-info-line'}
                    color={'var(--green-polichat)'}
                    size={24}
                  />
                  <p className="alternative">Central de Ajuda</p>
                  <Icon
                    icon={'poli-icon poli-icon pi-simple-arrow-right-line'}
                    color={'var(--gray-75)'}
                    position={'relative'}
                    size={15}
                  />
                </DropdownItem>

                <DropdownItem
                  className="itemDropdownMenu"
                  onClick={() => window.open('mailto:contato@polichat.com.br')}
                >
                  <Icon
                    icon={'poli-icon pi-mail-line'}
                    color={'var(--green-polichat)'}
                    size={24}
                  />
                  <p className="alternative">Suporte via E-mail</p>
                  <Icon
                    icon={'poli-icon poli-icon pi-simple-arrow-right-line'}
                    color={'var(--gray-75)'}
                    size={15}
                  />
                </DropdownItem>

                <DropdownItem
                  className="itemDropdownMenu"
                  onClick={() => {
                    window.open(
                      'https://web.whatsapp.com/send?phone=5562999800123'
                    );
                  }}
                >
                  <Icon
                    icon={'poli-icon pi-whatsapp-line'}
                    color={'var(--green-polichat)'}
                    size={24}
                  />
                  <p className="alternative">Suporte via WhatsApp</p>
                  <Icon
                    icon={'poli-icon poli-icon pi-simple-arrow-right-line'}
                    color={'var(--gray-75)'}
                    size={15}
                  />
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>

          <div className="operatorMenu" id={idOperator}>
            <Dropdown direction="right" isOpen={operatorMenu} toggle={toggle}>
              <DropdownToggle color={'neutral'}>
                <Avatar
                  title={nameOperator}
                  url={avatarOperator}
                  extraClass="avatarSidebar"
                  size={30}
                />
                <p className="operatorName">{nameOperator}</p>
              </DropdownToggle>
              <DropdownMenu className="menuDropdownShow container__operator--menu">
                <DropdownItem className="itemDropdownMenu" onClick={myAccount}>
                  <Icon
                    icon={'poli-icon pi-setting-line'}
                    color={'var(--green-polichat)'}
                    size={20}
                  />
                  <p className="alternative">Minha Conta</p>
                  <Icon
                    icon={'poli-icon poli-icon pi-simple-arrow-right-line'}
                    color={'var(--green-polichat)'}
                    position={'relative'}
                    size={20}
                  />
                </DropdownItem>
                <ToggleNotifications />
                <div className="mobileTopActions">
                  {multiCustomers && (
                    <>
                      <Organizations />
                      <br />
                    </>
                  )}
                  <DarkMode />
                </div>

                <DropdownItem
                  className="itemDropdownMenu operatorExitPolichat"
                  onClick={logout}
                >
                  <Icon
                    icon={'poli-icon pi-out-fill'}
                    color={'var(--red-polichat)'}
                    size={20}
                  />
                  <p className="alternative">Sair</p>
                  <Icon
                    icon={'poli-icon poli-icon pi-simple-arrow-right-line'}
                    color={'var(--red-polichat)'}
                    size={20}
                  />
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </>
      )}

      <div className="showMenuRetratil" onClick={handleMenu}>
        {menuRetratil ? (
          <Icon
            position={'relative'}
            top={'2px'}
            left={'-3px'}
            icon={'poli-icon poli-icon pi-next-line'}
          />
        ) : (
          <Icon
            position={'relative'}
            top={'2px'}
            icon={'poli-icon poli-icon pi-previous-line'}
          />
        )}
        <span>Recolher Menu</span>
      </div>

      <div className="showVersion">
        <span className="numberVersion">
          <span className="textVersion">
            Versão:
            <br />
          </span>
          {packageJson?.version}
        </span>
      </div>
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({
  config: state.chat.config,
  userConfig: state?.general?.user,
  props: ownProps,
  current_customer_id: state.general?.current_customer_id,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SidebarWhitelabel);
