import { useContext, useState } from 'react';
import { connect } from 'react-redux';
import '../details/crmDetails/details.css';
import ContactProfile from './ContactProfile';

import {
  Button,
  Card,
  CardBody,
  Collapse,
  Icon,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip,
} from '@polichat/flamboyant';
import Tag from '../../../chat/content/chat/modals/tags/Tag';

import { bindActionCreators } from 'redux';
import * as ChatActions from '../../../../store/modules/chat/actions';

import MergeContact from './MergeContact';
import UnmergeContact from './UnmergeContact';

import { isMobile } from 'react-device-detect';
import { Prompt } from 'react-router-dom';
import { GlobalContext } from 'src/context/globalContext';
import formatPhone from '../../../../utils/formatPhone';
import ValidationPage from '../details/crmDetails/NewClientInfo';
import ContactInfoModal from './crmDetails/ContactInfoModal';
import { OpportunitiesAndTickets } from './crmDetails/OpportunitiesAndTicketsDetails';
import { OpportunitiesBox } from './crmDetails/OpportunitiesBox';
import { TicketsBox } from './crmDetails/TicketsBox';

import { useFlags } from 'flagsmith/react';
import { fetchOpportunitiesAndTicketsRequest } from 'src/store/modules/chat/actions';
import store from 'src/store';
function Details({
  chatDetails,
  chat,
  openContactTab,
  setOpenAddContact,
  setOpenImportContact,
  setOpenEditContact,
  contacts,
  contactWithCards, // contato atual com cards
  allTags,
  removeTagFromContactRequest,
  users,
  showDetailsComponent,
  showBlockTwo,
  getTags,
  crm,
  openTagList,
}) {
  const crmNewChatDetails = useFlags(['crm_new_chat_details'])
    .crm_new_chat_details?.enabled;
  const cards =
    chat.contact?.id !== contactWithCards?.contact ? null : contactWithCards?.cards;
  if (crmNewChatDetails) {
    const { pictureGlobal } = useContext(GlobalContext);

    const [modalOpenPhoto, setModalOpenPhoto] = useState(false);
    const toggleOpenPhoto = () => setModalOpenPhoto((prev) => !prev);

    const [isOpenOpportunities, setIsOpenOpportunities] = useState(false);
    const toggleOpportunities = () => {
      setIsOpenOpportunities((prev) => !prev);
      if (!isOpenOpportunities && !contactWithCards.all) {
        store.dispatch(fetchOpportunitiesAndTicketsRequest(chat.contact?.id, true));
      }
    };

    const [isOpenTickets, setIsOpenTickets] = useState(false);
    const toggleTickets = () => {
      setIsOpenTickets((prev) => !prev);
      if (!isOpenTickets && !contactWithCards.all) {
        store.dispatch(fetchOpportunitiesAndTicketsRequest(chat.contact?.id, true));
      }
    };

    const [isOpen, setIsOpen] = useState(false);
    const toggleModal = () => {
      setIsOpen([(prev) => !prev]);
    };

    const hasCrm = crm?.permissions?.customerPermission;
    const hasPermission = crm?.permissions?.userPermission;

    let currentUserId = chat.contact.id;
    let currentContact = contacts?.find(
      (contact) => contact.id === currentUserId
    );

    const checkCrmPermission = () => {
      if (hasCrm === true && hasPermission === true) {
        return true;
      } else {
        return false;
      }
    };

    const validationCanViewCrmDetails = checkCrmPermission(
      hasCrm,
      hasPermission
    );

    // Reponsável por abrir a tela de editar contato
    function handleEditContact(value) {
      if (!currentContact) {
        currentContact = chat.contact;
      }

      let currentObject = {
        id: currentContact.id,
        name: currentContact.name,
        organization: currentContact.organization,
        company: currentContact.company,
        address: currentContact.address,
        cpf: currentContact.cpf,
        email: currentContact.email,
        phone: currentContact.phone,
        operator: currentContact.operator,
        externals: currentContact.externals,
      };

      // Abre o componente de contatos  e fecha o de chat
      openContactTab();
      // Se as telas de Adicionar ou Importar estiverem abertas, irão fechar
      setOpenAddContact(false);
      setOpenImportContact(false);
      setOpenEditContact(value, currentObject);
    }

    if (chatDetails) {
      return (
        <>
          {isOpenOpportunities ? (
            <OpportunitiesBox
              contact={chat.contact}
              cards={cards}
              toggleOpportunities={toggleOpportunities}
              toggleModal={toggleModal}
              isOpen={isOpenOpportunities}
            />
          ) : isOpenTickets ? (
            <TicketsBox
              contact={chat.contact}
              cards={cards}
              toggleTickets={toggleTickets}
              toggleModal={toggleModal}
              isOpen={isOpenTickets}
            />
          ) : (
            <div className="detailsContainer">
              <div className="boxContainerInfos">
                <ContactProfile
                  id={chat?.contact?.id}
                  onClick={toggleOpenPhoto}
                  organization={chat.contact.organization}
                  name={chat.contact.name}
                  avatarURL={
                    pictureGlobal ? pictureGlobal : chat.contact.picture
                  }
                  phone={
                    chat.contact.type === 'user'
                      ? formatPhone(chat.contact.phone)
                      : 'Grupo'
                  }
                />
                <div className="mergeContactButtonContainer">
                  <MergeContact contact={chat.contact} />
                  <Button
                    sx={6}
                    color="info"
                    className="ContactButton"
                    onClick={() => handleEditContact(true)}
                  >
                    <Icon icon={'poli-icon pi-pencil-fill'} />{' '}
                  </Button>
                  <ContactInfoModal
                    sx={6}
                    color="info"
                    className="ContactButton"
                    contact={chat}
                    allTags={allTags}
                    getTags={getTags}
                    removeTagFromContactRequest={removeTagFromContactRequest}
                    openTagList={openTagList}
                  />
                </div>
              </div>
              {isMobile && (
                <Prompt
                  message={(_loc, action) => {
                    if (action === 'POP') {
                      showDetailsComponent(false);
                      showBlockTwo();
                    }
                    return true;
                  }}
                />
              )}
              <Modal
                isOpen={modalOpenPhoto}
                toggle={toggleOpenPhoto}
                className={'modalLightBox modalLightBoxOpenPhoto'}
              >
                <ModalHeader toggle={toggleOpenPhoto}>
                  <div className="btnActionLightBox">
                    <span
                      onClick={() => {
                        setModalOpenPhoto(false);
                      }}
                    >
                      <Icon
                        icon="poli-icon pi-close-line"
                        size={24}
                        color="#fff"
                      />
                    </span>
                  </div>
                </ModalHeader>
                <ModalBody>
                  <img
                    alt={chat.contact.name}
                    title={chat.contact.name}
                    src={pictureGlobal ? pictureGlobal : chat.contact.picture}
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      margin: 0,
                      padding: 0,
                      borderRadius: 0,
                    }}
                  />
                </ModalBody>
              </Modal>

              <div className="scrollable-content">
                <div className="boxContainerCrm">
                  {!validationCanViewCrmDetails ? (
                  <ValidationPage permissions={crm?.permissions} organization={users[0]?.customer_id} />
                  ) : (
                    <>
                      <div className="moreInfosOpportunities">
                        <div className="title" onClick={toggleModal}>
                          <Collapse isOpen={isOpen}>
                            <CardBody className="infoItems">
                              <ContactInfoModal contact={chat} />
                            </CardBody>
                          </Collapse>
                        </div>
                      </div>
                      <OpportunitiesAndTickets
                        contact={chat.contact}
                        cards={cards}
                        onlyFirst={true}
                        toggleOpportunities={toggleOpportunities}
                        toggleTickets={toggleTickets}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      );
    }
    return <div></div>;
  } else {
    const { pictureGlobal } = useContext(GlobalContext);

    const [modalOpenPhoto, setModalOpenPhoto] = useState(false);

    const toggleOpenPhoto = () => setModalOpenPhoto(!modalOpenPhoto);

    const chatOperator = users?.find(
      (user) => user.id === chat.contact.operator
    );
    const [isOpenInfos, setIsOpenInfos] = useState(false);
    const toggleInfos = () => setIsOpenInfos(!isOpenInfos);
    let chatMessages = chat?.messages;
    let contactNameOriginal;
    let currentUserId = chat.contact.id;
    let currentContact = contacts?.find(
      (contact) => contact.id === currentUserId
    );

    const loadChatTags = () => {
      let chatTags = <>Sem etiquetas</>;

      if (chat.tags && chat.tags.length) {
        chatTags = chat.tags.map((currentTag, index) => {
          let tag = allTags?.find((t) => t.id === currentTag.tag_id);

          if (tag) {
            let obj = {
              chat_id: chat.id,
              tag_id: currentTag.id,
            };
            return (
              <Tag
                key={index}
                onRemove={() => removeTagFromContactRequest(obj)}
                color={tag.color}
                name={tag.name}
                close
              />
            );
          } else {
            return chatTags;
          }
        });
      }

      if (chat.tags && chat.tags.length) {
        return (
          <div className="contactHasTag">
            <Icon
              icon={'poli-icon pi-tag-line'}
              color={'var(--green-polichat)'}
              size={24}
            />
            {chatTags}
          </div>
        );
      } else {
        return <></>;
      }
    };

    let iconInfos = isOpenInfos
      ? 'poli-icon pi-simple-arrow-up-line'
      : 'poli-icon pi-simple-arrow-down-line';

    // Reponsável por abrir a tela de editar contato
    function handleEditContact(value) {
      if (!currentContact) {
        currentContact = chat.contact;
      }

      let currentObject = {
        id: currentContact.id,
        name: currentContact.name,
        organization: currentContact.organization,
        address: currentContact.address,
        cpf: currentContact.cpf,
        email: currentContact.email,
        phone: currentContact.phone,
        operator: currentContact.operator,
        externals: currentContact.externals,
      };

      // Abre o componente de contatos  e fecha o de chat
      openContactTab();
      // Se as telas de Adicionar ou Importar estiverem abertas, irão fechar
      setOpenAddContact(false);
      setOpenImportContact(false);
      setOpenEditContact(value, currentObject);
    }
    if (chatDetails) {
      return (
        <>
          <div
            style={{
              backgroundColor: 'var(--secondary-background-color)',
              border: '1px solid var(--border-color)',
              borderRadius: '5px',
              cursor: 'default',
              padding: '1em',
            }}
          >
            <ContactProfile
              id={chat?.contact?.id}
              onClick={toggleOpenPhoto}
              name={chat.contact.name}
              avatarURL={pictureGlobal ? pictureGlobal : chat.contact.picture}
              phone={
                chat.contact.type === 'user'
                  ? formatPhone(chat.contact.phone)
                  : 'Grupo'
              }
            />

            {/**
             * No celular, ao pressionar o botão de voltar
             * a aba de detalhes do contato é fechada e o
             * usuário volta para a tela da conversa (bloco 2)
             */}
            {isMobile && (
              <Prompt
                message={(_loc, action) => {
                  if (action === 'POP') {
                    showDetailsComponent(false);
                    showBlockTwo();
                  }
                  return true;
                }}
              />
            )}
            <Modal
              isOpen={modalOpenPhoto}
              toggle={toggleOpenPhoto}
              className={'modalLightBox modalLightBoxOpenPhoto'}
            >
              <ModalHeader toggle={toggleOpenPhoto}>
                <div className="btnActionLightBox">
                  <span
                    onClick={() => {
                      setModalOpenPhoto(false);
                    }}
                  >
                    <Icon
                      icon="poli-icon pi-close-line"
                      size={24}
                      color="#fff"
                    />
                  </span>
                </div>
              </ModalHeader>
              <ModalBody>
                <img
                  alt={chat.contact.name}
                  title={chat.contact.name}
                  src={pictureGlobal ? pictureGlobal : chat.contact.picture}
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    margin: 0,
                    padding: 0,
                    borderRadius: 0,
                  }}
                />
              </ModalBody>
            </Modal>
            <div className="boxContainerInfos" style={{ marginTop: '1em' }}>
              <div className="moreInfosContact">
                <div className="title" onClick={toggleInfos}>
                  <h5>Informações</h5>
                  <Icon
                    icon={iconInfos}
                    color={'var(--secondary-font-color)'}
                    size={20}
                  />
                </div>

                <Collapse isOpen={isOpenInfos}>
                  <Card className="infoItemsContainer">
                    <CardBody className="infoItems">
                      {chat.contact.email ? (
                        <div className="email infoItem" id="infoEmail">
                          <Icon
                            icon={'poli-icon pi-mail-line'}
                            color={'var(--green-polichat)'}
                            size={24}
                          />
                          <UncontrolledTooltip
                            placement="bottom"
                            target="infoEmail"
                          >
                            E-mail
                          </UncontrolledTooltip>
                          <span>
                            {chat.contact.email || 'E-mail não cadastrado'}
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}

                      {chat.contact.phone ? (
                        <div className="phone infoItem" id="infoPhone">
                          <Icon
                            icon={'poli-icon pi-phone-line'}
                            color={'var(--green-polichat)'}
                            size={24}
                          />
                          <UncontrolledTooltip
                            placement="bottom"
                            target="infoPhone"
                          >
                            Telefone
                          </UncontrolledTooltip>
                          <span>
                            {chat.contact.type === 'user'
                              ? formatPhone(chat.contact.phone)
                              : 'Grupo'}
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}

                      {chat.contact.organization ? (
                        <div
                          className="organization infoItem"
                          id="infoOrganization"
                        >
                          <Icon
                            icon={'poli-icon pi-company-line'}
                            color={'var(--green-polichat)'}
                            size={24}
                          />
                          <UncontrolledTooltip
                            placement="bottom"
                            target="infoOrganization"
                          >
                            Empresa
                          </UncontrolledTooltip>
                          <span>{chat.contact.organization}</span>
                        </div>
                      ) : (
                        <></>
                      )}

                      {chat.contact.address ? (
                        <div className="address infoItem" id="infoAddress">
                          <Icon
                            icon={'poli-icon pi-home-line'}
                            color={'var(--green-polichat)'}
                            size={24}
                          />
                          <UncontrolledTooltip
                            placement="bottom"
                            target="infoAddress"
                          >
                            Endereço
                          </UncontrolledTooltip>
                          <span>{chat.contact.address}</span>
                        </div>
                      ) : (
                        <></>
                      )}

                      {chat.tags && chat.tags.length ? (
                        <div
                          className="infoItemTags infoItem"
                          id="infoItemTags"
                        >
                          <div className="tagsListDetails">
                            {loadChatTags()}
                          </div>
                          <UncontrolledTooltip
                            placement="bottom"
                            target="infoItemTags"
                          >
                            Etiquetas
                          </UncontrolledTooltip>
                        </div>
                      ) : (
                        <></>
                      )}

                      {chatOperator ? (
                        <div className="operator infoItem" id="infoOperator">
                          <Icon
                            icon={'poli-icon pi-operator-line'}
                            color={'var(--green-polichat)'}
                            size={24}
                          />
                          <UncontrolledTooltip
                            placement="bottom"
                            target="infoOperator"
                          >
                            Atendente
                          </UncontrolledTooltip>
                          <span>
                            {chatOperator !== undefined
                              ? chatOperator.name
                              : 'Nenhum Operador'}
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
            </div>
            <div
              className="boxContainerInfos listChannelsContactContainer"
              style={{ marginTop: '1em' }}
            >
              <UnmergeContact contact={chat.contact} />
            </div>
            <div
              style={{
                alignItems: 'center',
                borderRadius: '5px',
                display: 'flex',
                justifyContent: 'center',
                margin: '1.2em 0 0',
              }}
            >
              <MergeContact contact={chat.contact} />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '1.2em 0 0',
              }}
            >
              <Button
                color="info"
                style={{
                  alignItems: 'center',
                  borderRadius: '5px',
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                }}
                size="lg"
                onClick={() => handleEditContact(true)}
              >
                <Icon icon={'poli-icon pi-pencil-fill'} size={20} />{' '}
                <span>Editar Contato</span>
              </Button>
            </div>
          </div>
        </>
      );
    }
    return <div></div>;
  }
}

const mapStateToProps = (state) => ({
  chatDetails: state.chat.showDetails,
  chat: state.chat.chats?.find((chat) => chat.open === true),
  contacts: state.chat?.contacts,
  contactWithCards: state.chat?.currentContact,
  allTags: state.chat?.config?.general?.tags,
  openChatWithDetails: state.chat?.chats?.find((chat) => {
    return chat.showDetails === true;
  }),
  users: state.chat.config.general?.users,
  crm: state.chat.config.general?.crm,
  openTagList: state.chat?.openTagList,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ChatActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Details);
