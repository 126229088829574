export const actionNames = {
  REQUEST_LOGIN: '@auth/REQUEST_LOGIN',
  REQUEST_LOGIN_WL: '@auth/REQUEST_LOGIN_WL',
  REQUEST_LOGIN_SUCCESS: '@auth/REQUEST_LOGIN_SUCCESS',
  REQUEST_LOGIN_FAILURE: '@auth/REQUEST_LOGIN_FAILURE',
  LOGOUT: '@auth/LOGOUT',
  LOGOUT_SUCCESS: '@auth/LOGOUT_SUCCESS',
};

export function requestLogin(email, password) {
  return {
    type: actionNames.REQUEST_LOGIN,
    payload: { email, password },
  };
}

export function requestLoginWl(token, customer_id) {
  return {
    type: actionNames.REQUEST_LOGIN_WL,
    payload: { token, customer_id },
  };
}

export function requestLoginSuccess(access) {
  return {
    type: actionNames.REQUEST_LOGIN_SUCCESS,
    payload: access,
  };
}

export function requestLoginFailure(error) {
  return {
    type: actionNames.REQUEST_LOGIN_FAILURE,
    payload: error,
  };
}

export function logout(data = {}) {
  return {
    type: actionNames.LOGOUT,
    payload: data,
  };
}

export function logoutSuccess() {
  return {
    type: actionNames.LOGOUT_SUCCESS,
  };
}
