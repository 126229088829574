import { put, all, call, takeLatest, select } from 'redux-saga/effects';
import {
  requestLogin,
  requestLoginFailure,
  requestLoginSuccess,
  actionNames,
  logoutSuccess,
} from './actions';

import {
  login,
  laravelLogin,
  loginApiGatewayWl,
  laravelLoginWlWithToken,
  laravelLoginWl,
  laravelLogout,
} from '../../../services/auth/login';

import { setCurrentCustomerId } from '../general/actions';

function* loginRequest(event) {
  try {
    let result = yield call(login, event.payload);

    if (!result.token) {
      throw new Error('Token not received!');
    }

    let current_customer_id = result.user.customers[0];

    const whiteLabelState = yield select(state => state?.whitelabel);

    if(whiteLabelState?.config && whiteLabelState?.config.is_white_label){
      if (!whiteLabelState.config.laravel_url) throw new Error("URL não fornecida para autenticação!");
      yield call(laravelLoginWl, event.payload, current_customer_id, whiteLabelState.config.laravel_url); //Faz login no laravel, selecionando primeira empresa
    }
    else{
      yield call(laravelLogin, event.payload, current_customer_id); //Faz login no laravel, selecionando primeira empresa
    }

    yield put(setCurrentCustomerId(current_customer_id)); //Adiciona no redux a empresa logada

    window.localStorage.setItem('poli_access', JSON.stringify(result));
    yield put(requestLoginSuccess(result));
  } catch (e) {
    console.log('login error', e);
    yield put(requestLoginFailure(e));
  }
}

//Login via token pela URL
function* loginRequestWl(event) {
  try {
    let result = yield call(loginApiGatewayWl, event.payload);

    if (!result.token) {
      throw new Error('Token not received!');
    }

    let current_customer_id = result.user.customers[0];

    const token = result.token;

    const getStateConfig = (state) => state?.whitelabel?.config
    const whitelabelConfig = yield select(getStateConfig);

    if (!whitelabelConfig?.laravel_url) throw new Error("URL não fornecida para autenticação!");

    yield call(laravelLoginWlWithToken, {token}, current_customer_id, whitelabelConfig.laravel_url); //Faz login no laravel, selecionando primeira empresa

    yield put(setCurrentCustomerId(current_customer_id)); //Adiciona no redux a empresa logada

    window.localStorage.setItem('poli_access', JSON.stringify(result));
    yield put(requestLoginSuccess(result));
  } catch (e) {
    console.log('login error', e);
    yield put(requestLoginFailure(e));
  }
}

function* logoutRequest(event) {
  try {
    window.localStorage.removeItem('poli_access');
    window.localStorage.removeItem('spa_current_customer_id');
    window.localStorage.removeItem('current_laravel_url');

    if (!(event.payload.logout_laravel == false)) yield call(laravelLogout); //Faz logout no laravel

    yield put(logoutSuccess());
  } catch (e) {
    console.log('login error', e);
  }
}

export default all([
  takeLatest(actionNames.REQUEST_LOGIN, loginRequest),
  takeLatest(actionNames.REQUEST_LOGIN_WL, loginRequestWl),
  takeLatest(actionNames.LOGOUT, logoutRequest),
]);
